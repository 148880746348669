// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
// import alert from '../../utils/alert';
// import { useSearchParams } from 'react-router-dom';
// import Axios from '../../axios/axiosInstance';


// const PlanAndPayment = () => {
//   const [toggle, setToggle] = useState(false)
//   const navigate = useNavigate()
//   const [currentPlan, setCurrentPlan] = useState()


//   useEffect(() => {
//     getPlans()
//   }, [])


//   const getPlans = async () => {
//     // showLoader();
//     await Axios.get("plans/current")
//       .then((res) => {
//         console.log("ressssssssss", res.data.data)
//         setCurrentPlan(res.data.data)
//       })
//       .catch((err) => {
//         err.response && alert("error", err.response.data.error);
//       })
//       .finally(() => {
//         // hideLoader();
//       });
//   };


//   const formatDate = (dateString) => {
//     const date = new Date(dateString);

//     // Month names array
//     const monthNames = [
//       "January", "February", "March", "April", "May", "June",
//       "July", "August", "September", "October", "November", "December"
//     ];

//     // Get month, day, hour, and minute
//     const month = monthNames[date.getMonth()];
//     const day = date.getDate();
//     const hour = date.getHours();
//     const minute = date.getMinutes();

//     // Function to get the ordinal suffix for the day (e.g., "st", "nd", "rd", "th")
//     const getOrdinalSuffix = (day) => {
//       if (day >= 11 && day <= 13) {
//         return "th";
//       }
//       switch (day % 10) {
//         case 1:
//           return "st";
//         case 2:
//           return "nd";
//         case 3:
//           return "rd";
//         default:
//           return "th";
//       }
//     };

//     // Format the date
//     const formattedDate = `${month} ${day}${getOrdinalSuffix(day)} ${hour}:${minute.toString().padStart(2, '0')}`;

//     return formattedDate;
//   };




//   return (
//     <>
//       <div className="heading mb-3"><img onClick={() => navigate(-1)} src="img/backicon.svg" alt="back icon" className='me-2 pointer' /> Plan & Payment</div>
//       <div className="row">
//         <div className="col-md-6">

//           <div className="chooseplan px-3 py-4 border--gray border--rad10">
//             <div className="heading">Your current plan</div>
//             <div className="center_line--dash my-3" />
//             <div className="chooseplan__header d-flex align-items-center justify-content-between mb-3">
//               <div className="col-left d-flex align-items-center">
//                 {(currentPlan?.name === "BuildFolio platinum monthly" || currentPlan?.name === "BuildFolio platinum Yearly") &&
//                   <>
//                     <img src="img/platinum.svg" alt="plan icon" className='plan__icon me-2' />
//                     <div className="plan__txt">Platinum</div>
//                   </>
//                 }
//                 {(currentPlan?.name === "Buildfolio Diamond Monthly" || currentPlan?.name === "BuildFolio Diamond Yearly") &&
//                   <>
//                     <img src="img/diamond.svg" alt="plan icon" className='plan__icon me-2' />
//                     <div className="plan__txt">Diamond</div>
//                   </>
//                 }
//               </div>
//               <div className="col-right">
//                 {currentPlan?.subscription_status === "active" &&
//                   <div className="active-text-btn-green">Active</div>}
//               </div>
//             </div>
//             {(currentPlan?.name === "BuildFolio platinum monthly" || currentPlan?.name === "BuildFolio platinum Yearly") &&
//               <div className="chooseplan--details">
//                 <ul className="details--group ps-0">
//                   <li className='details--list d-flex'>
//                     <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
//                     Can post unlimited jobs.
//                   </li>
//                   <li className='details--list d-flex'>
//                     <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
//                     In case any new permit will be issued in your
//                     locality, you will get notified.
//                   </li>
//                   <li className='details--list d-flex'>
//                     <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
//                     Get optimized website designed and hosted.
//                   </li>
//                 </ul>
//               </div>}
//             {(currentPlan?.name === "Buildfolio Diamond Monthly" || currentPlan?.name === "BuildFolio Diamond Yearly") &&
//               <div className="chooseplan--details">
//                 <ul className="details--group ps-0">
//                   <li className='details--list d-flex'>
//                     <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
//                     Can post unlimited jobs.
//                   </li>
//                   <li className='details--list d-flex'>
//                     <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
//                     In case any new permit will be issued in your locality, you will get notified.
//                   </li>
//                   <li className='details--list d-flex'>
//                     <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
//                     Get optimized website designed and hosted.
//                   </li>
//                 </ul>
//               </div>}
//           </div>
//           <div className="mt-5 d-flex align-items-center justify-content-between">
//             <button onClick={() => navigate("/choose-plan")} className="btn_cus wfc py-2 ">{!toggle ? "Upgrade to Diamond" : "Renew Subscription"}</button>
//             <button onClick={() => {
//               if (!toggle) {
//                 setToggle(!toggle)
//                 alert("success", "Subscription cancelled successfully")
//               }
//             }} className=" btn_cus wfc  py-2 shadow-none  text-dark bg-transparent">{toggle ? "Cancelled" : "Cancel Subscription"}</button>
//           </div>
//           <div className="text-center mt-5 fz14 graytxt DMSans_bold">Platinum Plan Expires on {currentPlan?.subscription_end_date ? formatDate(currentPlan?.subscription_end_date):"N/A" } </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default PlanAndPayment;



import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from '../../axios/axiosInstance';
import alert from '../../utils/alert';
import useLoader from '../../components/loaders/useLoader';
import { useAppContext } from '../../context/SharedData';

const PlanAndPayment = () => {
  const [toggle, setToggle] = useState(false);
  const { showLoader, hideLoader, Loader, isLoading } = useLoader();
  const [currentPlan, setCurrentPlan] = useState(null)
  const navigate = useNavigate();
  const { setRefreshPage, refreshPage } = useAppContext();

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = async () => {
    showLoader()
    try {
      const res = await Axios.get("subscription/current-plan");
      setCurrentPlan(res.data.data);
    } catch (err) {
      console.error("Error fetching plans:", err);
      alert("error", err.response?.data?.error || "Failed to fetch plans.");
    } finally {
      hideLoader()
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const options = {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      ordinal: 'auto' // this can handle ordinal suffixes
    };
    return date.toLocaleDateString('en-US', options);
  };

  const cancelPlan = async (id) => {
    showLoader()
    try {
      const res = await Axios.patch("subscription/cancel/" + id);
      // setCurrentPlan(res.data.data);
    } catch (err) {
      console.error("Error fetching plans:", err);
      alert("error", err.response?.data?.error || "Failed to fetch plans.");
    } finally {
      setRefreshPage(true)
      hideLoader()
      getPlans()
    }
  };

  const utc = new Date(currentPlan?.subscription_end_date);
  let offset = utc.getTimezoneOffset();
  let subscriptionEndDate = new Date(utc.getTime() + offset * 60000);
  const currentDate = new Date();



  return (
    <>
      <Loader />
      <div className="heading mb-3">
        <img
          onClick={() => navigate(-1)}
          src="img/backicon.svg"
          alt="back icon"
          className="me-2 pointer"
        />
        Plan & Payment
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="chooseplan px-3 py-4 border--gray border--rad10">
            <div className="heading">Your current plan</div>
            <div className="center_line--dash my-3" />
            <div className="chooseplan__header d-flex align-items-center justify-content-between mb-3">
              <div className="col-left d-flex align-items-center">
                {currentPlan?.name && (
                  <>
                    <img
                      src={`img/${currentPlan?.name?.toLowerCase().includes('platinum') ? 'platinum' : 'diamond'}.svg`}
                      alt="plan icon"
                      className="plan__icon me-2"
                    />
                    <div className="plan__txt">
                      {currentPlan?.name?.toLowerCase().includes('platinum') ? 'Platinum' : 'Diamond'}
                    </div>
                  </>
                )}
                {currentDate < subscriptionEndDate && (
                  <div className="active-text-btn-green px-3 ms-2">Active</div>
                )}
              </div>
              <div className="col-right">
                <h4>{currentPlan?.name?.toLowerCase().includes('yearly') ? "Yearly" : "Monthly"}</h4>
              </div>
            </div>
            {currentPlan?.name && currentPlan.name.toLowerCase().includes('diamond') ?
              <div className="chooseplan--details">
                <ul className="details--group ps-0">
                  <li className="details--list d-flex">
                    <img src="img/check_fill-small-teal.svg" alt="check icon" className="checkIcon" />
                    Can post unlimited jobs.
                  </li>
                  <li className="details--list d-flex">
                    <img src="img/check_fill-small-teal.svg" alt="check icon" className="checkIcon" />
                    In case any new permit will be issued in your locality, you will get notified.
                  </li>
                  <li className="details--list d-flex">
                    <img src="img/check_fill-small-teal.svg" alt="check icon" className="checkIcon" />
                    Get optimized website designed and hosted.
                  </li>
                </ul>
              </div> :
              <div className="chooseplan--details">
                <ul className="details--group ps-0">
                  <li className='details--list d-flex'>
                    <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                    Can post unlimited jobs.
                  </li>
                  <li className='details--list d-flex'>
                    <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                    In case any new permit will be issued in your locality, you will get notified.
                  </li>
                </ul>
              </div>}
          </div>
          <div className="mt-5 d-flex align-items-center justify-content-between">
            <button
              onClick={() => {
                if (currentPlan?.name?.toLowerCase().includes('diamond') || currentPlan?.subscription_status !== "active") {
                  navigate("/choose-plan", { state: { isUpgradePlan: false } })
                } else {
                  navigate("/choose-plan", { state: { isUpgradePlan: true } })
                }
              }}
              className="btn_cus wfc py-2"
            >
              {(currentPlan?.name?.toLowerCase().includes('diamond') || currentPlan?.subscription_status !== "active") ? "Renew Subscription" : "Upgrade to Diamond"}
            </button>
            <button
              onClick={() => {
                if (currentPlan?.subscription_status === "active") {
                  cancelPlan(currentPlan?.subscription_id)
                }
                // if (!toggle) {
                //   setToggle(!toggle);
                //   alert("success", "Subscription cancelled successfully");
                // }
              }}
              className="btn_cus wfc py-2 shadow-none text-dark bg-transparent"
            >
              {currentPlan?.subscription_status === "active" ? "Cancel Subscription" : "Cancelled"}
            </button>
          </div>
          <div className="text-center mt-5 fz14 graytxt DMSans_bold">
            {currentPlan?.name?.toLowerCase().includes('diamond') ? "Diamond" : "Platinum"} Plan Expires on {formatDate(currentPlan?.subscription_end_date)}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanAndPayment;
