import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../context/SharedData";
import Axios from "../../axios/axiosInstance";
import Dropdown from "react-bootstrap/Dropdown";
import ToolTip from "./ToolTip";
import alert from "../../utils/alert";

const JobTypeFilter = () => {
  const dropdownRef = useRef(null);
  const location = useLocation();
  const { sharedData, setSharedData, setContractorDetails ,contractorDEtails} = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [allPostData, setAllPostData] = useState([]);
  const localPostId = localStorage.getItem("postId") || ""

  useEffect(() => {
    setCurrentPage(1);
  }, [location, localPostId]);

  useEffect(() => {
    if (localStorage.getItem("user_token")) getPostData();
  }, [currentPage, location]);

  const getPostData = async () => {
    await Axios.get(
      `posts?search=${sharedData?.search ? sharedData?.search : ""
      }&status=${"active"}&page=${currentPage}&limit=7&license_id=${localStorage.getItem("l_id")}`
    )
      .then((res) => {

        if (currentPage > 1) {
          const newPosts = res.data.post.filter(
            (newPost) => !allPostData.some((oldPost) => oldPost.id === newPost.id)
          );
          setAllPostData((prevData) => [...prevData, ...newPosts]);
        } else {
          setAllPostData(res.data.post);
        }

        if (localPostId) {
          const filterData = res?.data?.post?.filter((ele) => {
            return localPostId == ele.id;
          });
          setContractorDetails(filterData[0]);
      
        }
      })
      .catch((err) => {
        err.response && alert("error", err.response.data.error);
      })
      .finally(() => {
        //  hideLoader();
      });
  };
  
  // Load more data when dropdown reaches the bottom
  const handleDropdownScroll = () => {
    const dropdown = dropdownRef.current;
    if (dropdown.scrollTop + dropdown.clientHeight >= dropdown.scrollHeight) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleSelectHiringType = (e) => {
    const filterData = allPostData?.filter((ele) => {
      return e == ele.id;
    });
    // filterData.length > 0 && setZipcode(filterData[0].zip);
    setContractorDetails(filterData[0]);  //show data in bread crumb

    setSharedData((prevData) => ({
      ...prevData,
      type: filterData[0].type,
      zip: filterData[0].zip,
      jobFilter: filterData[0].type,
      postId: filterData[0].id,
    }));

    //persist data on reload
    localStorage.setItem("type", filterData[0].type)
    localStorage.setItem("zip", filterData[0].zip)
    localStorage.setItem("jobFilter", filterData[0].type)
    localStorage.setItem("postId", filterData[0].id)
  };




  return (
    <>
      {localStorage.getItem("user_token") && (
        <Dropdown onSelect={handleSelectHiringType} className="dropdownheader me-sm-3 me-2 max200">
          <Dropdown.Toggle
            style={{ color: "black" }}
            variant="secondary"
            className={`mt-0 ${sharedData.jobFilter ? "bg-litBlue" : null}`}
          >
            {sharedData.jobFilter || "Hiring for a job?"}
          </Dropdown.Toggle>

          <Dropdown.Menu onScroll={handleDropdownScroll} ref={dropdownRef}>
            <ToolTip allPostData={allPostData} />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default JobTypeFilter;
