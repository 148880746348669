import React, { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children, setRefreshUserPorofileApi, refreshUserProfileApi }) => {
  const [contractorDetails, setContractorDetails] = useState("");
  const [refreshPage, setRefreshPage] = useState(false)
  const [isWebsiteRedirected, setIsWebsiteRedirected] = useState(false)
  const [autoOpenDrawer, setAutoOpenDrawer] = useState("")
  const [subScriptionStatus, setSubscriptionStatus] = useState("")
  const [isClaimBtnActive, setIsClaimBtnActive] = useState(false)
  // Define the state and any functions you want to share here



  const [sharedData, setSharedData] = useState({
    search: null,
    contractorType: "",
    zipcode: 0,
    city: false,
  });

  useEffect(() => {
    if (refreshUserProfileApi) {
      setRefreshPage(true)
    }
    setRefreshUserPorofileApi(false)
  }, [refreshUserProfileApi])
  return (
    <AppContext.Provider
      value={{
        autoOpenDrawer, setAutoOpenDrawer,
        isClaimBtnActive, setIsClaimBtnActive,
        subScriptionStatus, setSubscriptionStatus,
        isWebsiteRedirected, setIsWebsiteRedirected,
        refreshPage,
        setRefreshPage,
        sharedData,
        contractorDetails,
        setContractorDetails,
        setSharedData, //common data in all the panels
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
