import { toast,Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 


const toastNotification = ({ title, description, status,handleClick }) => { 

  const ToastContent = ({ description, handleClick }) => (
    <div onClick={() => handleClick("toast")} style={{ display: 'flex', alignItems: 'center' }}>
      <div className='d-flex justify-content-center'>
      <img src="logo192.png" alt="logo" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
        <p className='text-white'>{description}</p>
      </div>
    </div>
  );

  toast.info(<ToastContent description={description} handleClick={handleClick} />, {
    icon: false,
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
    onClick: () => handleClick("toast")
  });
};

const sendNativeNotification = ({ title, body ,handleClick}) => {
  console.log("native notifications triggered")
  // if ('Notification' in window && Notification.permission === 'granted') {
  //   const notification = new Notification(title, {
  //     body,
  //     icon: '/favicon.ico', // Customize the icon path if needed
  //   });
  
  //   notification.onclick = (event) => {
  //     event.preventDefault(); // Prevent the browser from focusing the Notification's tab
  //     window.open("https://www.mozilla.org", "_blank"); // Open a new tab or perform your action
  //   };
  // }
  
};

export { toastNotification, sendNativeNotification };

