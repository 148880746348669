import React from "react";
import { Carousel } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useAppContext } from "../../context/SharedData";
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const UserWebsite = () => {
    const { isWebsiteRedirected, setIsWebsiteRedirected } = useAppContext()
    const navigate = useNavigate()
    const location = useLocation()
    const userDetails = location?.state?.userDetails
    const isUserVerified = location?.state?.isUserVerified

    const responsive = {
        0: { items: 2 },
        // 568: { items: 3 },
        // 1024: { items: 4 },
    };

    const items = [
        <div className="item" data-value="1">
            <img src={userDetails?.profile_pic ? process.env.REACT_APP_BUILDFOLIO_URL + "media/" + userDetails?.profile_pic : "img/webprofile.png"} alt="webprofile" className="webprofile w-100" />
        </div>,
        <div className="item" data-value="2">
            <img src={userDetails?.profile_pic ? process.env.REACT_APP_BUILDFOLIO_URL + "media/" + userDetails?.profile_pic : "img/webprofile.png"} alt="webprofile" className="webprofile  w-100" />
        </div>,
        <div className="item" data-value="3">
            <img src={userDetails?.profile_pic ? process.env.REACT_APP_BUILDFOLIO_URL + "media/" + userDetails?.profile_pic : "img/webprofile.png"} alt="webprofile" className="webprofile  w-100" />
        </div>,
        <div className="item" data-value="4">
            <img src={userDetails?.profile_pic ? process.env.REACT_APP_BUILDFOLIO_URL + "media/" + userDetails?.profile_pic : "img/webprofile.png"} alt="webprofile" className="webprofile  w-100" />
        </div>,
        <div className="item" data-value="5">
            <img src={userDetails?.profile_pic ? process.env.REACT_APP_BUILDFOLIO_URL + "media/" + userDetails?.profile_pic : "img/webprofile.png"} alt="webprofile" className="webprofile  w-100" />
        </div>,
    ];



    return <>
        <section className="contentWeb">

            <div className="webconatainer">
                <h1 className="mainheading text-center mb-4 ">{userDetails?.businessname}</h1>
                <div className="d-flex align-items-center mb-4">
                    <img onClick={() => {
                        navigate(-1)
                        !isUserVerified && setIsWebsiteRedirected(true)
                    }} src="img/backScreen.svg" alt="backScreen" className="pointer backScreen me-2" />
                    <h2 className="head2 mb-0">Profile</h2>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="webprofile-cont position-relative">
                            <img src={userDetails?.profile_pic ? process.env.REACT_APP_BUILDFOLIO_URL + "media/" + userDetails?.profile_pic : "img/webprofile.png"} alt="webprofile" className="webprofile" />
                            <div className="webprofile-on position-absolute"><span className="webprofile-name">{userDetails?.primaryprincipalname || "N/A"} </span>
                                {/* <span className="d-block webprofile-job">Electrician</span> */}
                            </div>
                        </div>

                    </div>
                    <div className="col-md-12 mt-4">
                        <h2 className="head2">About Me</h2>
                        <p className="webtxt">{userDetails?.description || "N/A"}</p>
                        {/* <p className="webtxt">For Charlie it’s all about good people and good times. You will find him doing hair for his next door neighbor and some of the world’s most beautiful women including Kendall Jenner, Gigi Hadid and Charlize Theron, to name a few. Or perhaps backstage during Fashion Week for notable houses like Chanel and Alice + Olivia, as well as up-and-coming brands like Baby Ghost and Greg Lauren.</p> */}
                    </div>
                </div>
            </div>
        </section>
        <section className="services-web">
            <div className="webconatainer">
                <div className="services-conatiner">
                    <h2 className="head2 text-center text-white">Services</h2>
                    <div className="services-grp mt-4">
                        {userDetails?.services.map((ele) => {
                            return <div className="services-item">{ele}</div>
                        })}
                    </div>

                </div>
            </div>
        </section>
        <section className="jobphoto">
            <div className="webconatainer">
                <h2 class="head2 text-md-center text-dark  mb-4">Job Photos</h2>
                <AliceCarousel
                    autoPlay
                    mouseTracking
                    items={userDetails?.job_photos?.length > 0 ? userDetails?.job_photos?.map((ele) => {
                        return <div className="item" data-value="1">
                            <img src={ele ? process.env.REACT_APP_BUILDFOLIO_URL + "media/" + ele : "img/webprofile.png"} alt="webprofile" className="webprofile w-100" />
                        </div>
                    }) : []}
                    responsive={responsive}
                    controlsStrategy="alternate"
                    // autoPlayControls
                    autoPlayStrategy="none"
                    autoPlayInterval={1000}
                    animationDuration={1000}
                    animationType="fadeout"
                    infinite
                    touchTracking={true}
                    disableDotsControls
                    disableButtonsControls
                />
                {userDetails?.job_photos?.length === 0 && <div className="text-center mb-5">
                    <h2 className="fs-2 text-center">No Job Photos Available</h2>

                </div>}
            </div>

        </section>
    </>
}


export default UserWebsite