
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Axios from "../../../axios/axiosInstance";
import alert from "../../../utils/alert";
import CustomModal from "../../../components/common/CustomModal";
import Select from "react-select";
import { JOB_POST_QUERY_PARAMS } from "../../../config/queryParams";

const WelcomeFirst = () => {
    const components = {
        DropdownIndicator: null,
    };


    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const isClaim = queryParams.get("isclaim") || false



    // const [businessName, setBusinessName] = useState("");
    const [options, setOptions] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [error, setError] = useState("")


    const handleSubmit = (event) => {
        event.preventDefault();

        // Handle form submission
        if (!selectedOption?.value) {
            alert("error", "Please enter a business name", { top: "20%", duration: 5 });
            return;
        }

        Axios.post("business-detail", {
            businessname: selectedOption?.value,
        })
            .then((res) => {
                if (res.data) {
                    setShow(true);
                    localStorage.setItem("l_id", res.data.id);
                    localStorage.setItem("p_no", res.data.phone_number
                    );
                }
            })
            .catch((err) => err.response && alert("error", err.response.data.error, { top: "20%", duration: 5 }));
    };

    const handleBusinessNameChange = async (newValue) => {

        const timer = newValue && setTimeout(async () => {
            try {
                const response = await Axios.get(`license/search?limit=10&name=${newValue}`);

                if (response.data.data.length > 0) {
                    const businessOptions = response.data.data.map((business) => ({
                        value: business?.businessname,
                        label: business?.businessname + " " + business.contractorlicensetypecode,
                        record: { licenseId: business?.id, phonenumber: business?.phonenumber, businessName: business.businessname }
                    }));
                    setOptions(businessOptions);
                }
            } catch (error) {
                alert("error", error.message);
            }
        }, 500); // Adjust debounce time as needed (e.g., 500ms)

        return () => clearTimeout(timer);
    };


    return (
        <>
            <h6 className="boldtxt_on mt30 text-center">Welcome! First, let’s start by looking up your business</h6>
            <p className="mb30 text-center">We’ll use this information to help you claim your Buildfolio profile.</p>


            <form onSubmit={handleSubmit}>
                <div className="min_hform">
                    {/* <p className="ortxtAfBE me-2 mt-4" style={{ textAlign: "center" }}>OR</p> */}
                    <div className="input_grp">
                        <div className="d-flex align-items-start">
                            <img src="img/business_icon.svg" alt="business_icon" className="icon_input me-2 mt-1" />
                            <div className="input_itm w-100">
                                <label htmlFor="businessName" className="d-block">Business Name</label>
                                <Select
                                    components={components}
                                    className="inputFill border-0"
                                    value={selectedOption || ""}
                                    options={options}
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setShow(true);
                                            localStorage.setItem("l_id", selectedOption.record.licenseId);
                                            localStorage.setItem("p_no", selectedOption.record.phonenumber)
                                        }
                                        setSelectedOption(selectedOption);
                                        handleBusinessNameChange(selectedOption?.value)

                                    }}
                                    onInputChange={(inputValue) => { handleBusinessNameChange(inputValue) }}
                                    placeholder="Enter Business Name"
                                    isClearable
                                />
                                <span></span>
                            </div>
                        </div>
                    </div>
                    {/* {businessName === "" && <span className="text-danger">Please enter a valid business name</span>} */}
                </div>

                {/* <button type="submit" className="btn_cus">CONTINUE</button> */}
                <div className="d-flex justify-content-between">
                    <p className="text-center mt-3">
                        <Link onClick={() => navigate("/")} className="bluedrk">Go to Homepage</Link>
                    </p>
                    <p className="text-center mt-3">
                        Back to{" "}
                        <Link to={`/login?${JOB_POST_QUERY_PARAMS(queryParams)}`} className="bluedrk">Log in</Link>
                    </p>
                </div>
            </form>

            <CustomModal
                handleChange={() => {
                    if (isClaim) {
                        navigate(`/verify-phone?isclaim=${isClaim}`)
                    } else {
                        navigate(`/verify-phone?${JOB_POST_QUERY_PARAMS(queryParams)}`)
                    }
                }
                }
                modalBodyText={{
                    header1: selectedOption?.value || "",
                    header2: "This business profile already exists!",
                    body2: "Verify that you are the owner of this profile and begin building profitable business relationships."
                }}
                type={"signup"}
                show={show}
                action={() => {
                    navigate("/login")
                }}
                handleClose={() => setShow(false)}
                onHide={() => setShow(false)}
            />
        </>
    );
};

export default WelcomeFirst;
