

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "../../../axios/axiosInstance";
import alert from "../../../utils/alert";
import CustomModal from "../../../components/common/CustomModal";
import { JOB_POST_QUERY_PARAMS } from "../../../config/queryParams";
import ProfileList from "./ProfileList";

const VerifyPhone = () => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({ mode: "onChange" });
    const userId = localStorage.getItem("user_id") || null
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [toggleScreen, setToggleScreen] = useState(false)
    const [profileList, setProfielList] = useState([])


    const isClaim = queryParams.get("isclaim") || false

    const [show, setShow] = useState(false);
    const [isClaimedAgain, setIsClaimedAgain] = useState(false)
    const [response, setResponse] = useState("")
    const [checkedArr, setCheckedArr] = useState([])

    useEffect(()=>{
        if(!localStorage.getItem("p_no")){
            alert("error","Session expired!")
            navigate("/login")
        }
    },[])

    const onSubmit = (data) => {

        if ((!data.businessName && !data.businessPhone) || (data.businessName && data.businessPhone)) {
            alert("error", "Please enter business phone number", { top: "20%", duration: 5 })
            return;
        }
        // isClaim == "true" ? Axios.post :
        const method = Axios.get;
        const endpoint = `license/${localStorage.getItem("l_id")}?phonenumber=${data.businessPhone}`;

        method(endpoint)
            .then((res) => {
                if (res) {
                    localStorage.setItem("b_name", res.data.name);
                    if (res.data.license_id_arr.length > 0) {
                        setResponse(res.data.message)
                        setProfielList(res.data.license_id_arr)
                        setToggleScreen(true)
                    } else {
                        if (res.data.message === "Your Account status is pending for verification") {
                            setShow(true);
                            setIsClaimedAgain(true);
                        } else {
                            setIsClaimedAgain(false);
                            setShow(true);
                        }
                    }
                }
            })
            .catch((err) => err.response && alert("error", err.response.data.error, { top: "20%", duration: 5 }));


    };


    const handleFinalCase = async (ids) => {


        await Axios.post(`license/update`, {
            license_id_arr: ids
        }).then((res) => {
            if (res) {
                setCheckedArr(ids)
                if (response === "Your Account status is pending for verification") {
                    setShow(true);
                    setIsClaimedAgain(true);
                } else {
                    setIsClaimedAgain(false);
                    // localStorage.setItem("b_name", res.data.name);
                    setShow(true);
                }

            }
        }).catch((err) => {
            alert("error", err.response.data.message)
        }).finally(() => {
            // hideLoader()
        })
    }

    return (
        <>  {!toggleScreen ?
            <>
                <h6 className="boldtxt_on mt30 text-center">Welcome! First, let’s start by looking up your business</h6>
                <p className="mb30 text-center">We’ll use this information to help you claim your Buildfolio profile.</p>
                <h6>Enter Your Business Phone Number ********{localStorage.getItem("p_no")}</h6>
                {/* {location?.state?.isSMSclicked &&
                <div className="noti_notEl d-flex mb-4">
                    <img src="img/notEqual.svg" alt="notEqual" />
                    <p className="mb-0 ms-2">Please create an account in order to send text messages
                        directly to other contractors.</p>
                </div>} */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="min_hform">
                        <div className="input_grp ">
                            <div className="d-flex align-items-start">
                                <img src="img/phone.svg" alt="phone" className="icon_input me-2 mt-1" />
                                <div className="input_itm w-100">
                                    <label htmlFor="phone" className="d-block">
                                        Business Phone No.<sup>*</sup>
                                    </label>
                                    <input
                                        type="text"
                                        id="phone"
                                        className="inputFill border-0"
                                        placeholder="Enter Business Phone Number"
                                        maxLength={10}
                                        {...register("businessPhone", {
                                            // required: false,
                                            validate: (value) => {
                                                if (value.trim() === '') return true; // Return true if field is empty
                                                // If field is not empty, apply other validation rules
                                                if (!/^[0-9]{10}$/.test(value)) {
                                                    return "Please enter a valid business phone number";
                                                }
                                                if (value.length !== 10) {
                                                    return "Phone no. must be 10 digits";
                                                }
                                                return true; // Return true if validation passes
                                            }


                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        {errors.businessPhone && <span className="text-danger">Please enter a valid business phone number</span>}
                    </div>
                    <button type="submit" className="btn_cus mt30">
                        CONTINUE
                    </button>
                    <p className="text-center mt-3">
                        Already have an account?{" "}
                        <Link to={`/login?${JOB_POST_QUERY_PARAMS(queryParams)}`} className="bluedrk">
                            Log in
                        </Link>
                    </p>
                </form>
            </> :
            <ProfileList profileList={profileList} handleFinalCase={handleFinalCase} />
        }
            {isClaimedAgain ?
                <CustomModal onHide={() => setShow(false)} handleChange={function action() { navigate(`/?${JOB_POST_QUERY_PARAMS(queryParams)}`) }} show={show} type={"signup"} handleClose={() => setShow(false)} modalBodyText={{
                    img: "img/congratulations.svg", header1: "Congratulations!", body1: "You are one step closer to success!", body2: "Your account status is: Pending Verification. You’ll be notified once it’s completed."
                }} />
                :
                <CustomModal
                    onHide={() => setShow(false)}
                    handleChange={() => {
                        localStorage.removeItem("p_no")
                        navigate(`/signup?${JOB_POST_QUERY_PARAMS(queryParams)}`, { state: { profileList: checkedArr } })
                    }}
                    modalBodyText={{ header1: getValues("businessName") || "", header2: "This business profile already exists!", body2: "Verify that you are the owner of this profile and begin building profitable business relationships." }}
                    type={"signup"} show={show} action={function action() { navigate("/login") }} handleClose={() => setShow(false)}
                />}


        </>
    );
};

export default VerifyPhone;
