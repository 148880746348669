

const resetLocalStorageItem = (key) => {
    localStorage.setItem(key, "");
};

export const resetLocalStorageItems = (...keys) => {
    keys.forEach(key => {
        resetLocalStorageItem(key);
    });
};
