import React, { useEffect, useState } from "react";
import useDivLoader from "../../components/loaders/useDivLoader";
import Axios from "../../axios/axiosInstance";
import { useParams } from "react-router-dom";

const NotificationDetails = () => {
    const { id, pId } = useParams();
    const { showLoader, hideLoader, Loader } = useDivLoader();
    const [details, setDetails] = useState(null);


    useEffect(() => {
        getData();
    }, [id, pId]);

    const getData = () => {
        showLoader();
        Axios.get(`notifications/${id}/permit/${pId}`)
            .then((res) => {
                setDetails(res.data.data); // Assuming res.data contains the full permit details object
            })
            .catch((err) => {
                console.error("Error fetching permit details:", err);
                alert("Error fetching permit details");
            })
            .finally(() => {
                hideLoader();
            });
    };

    const formatNewlines = (text) => {
        if (!text) return '';
        return text.replace(/\n/g, '<br />');
    };

    return <>
        <div className="modal_header mb-4 ldr">
            <Loader type={"tableLines"} />
            <h4>Permit Details</h4>
        </div>
        <div className="form_output">
            {details && (
                <>
                    <div className="input_grp">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Permit Number:<span>{details.permitnumber}</span></h4>
                            <div className="row">
                                <div className="col-md-6">
                                    {/* <p className="txt mb-2 ">Permit Type:<span></span></p> */}
                                    <p className="txt  mb-2 d-flex aligin-items-center justify-content-between"><span>Status:</span> <span className="text-dark">{details.status}</span></p>
                                    <p className="txt d-flex aligin-items-center justify-content-between"><span>Expiration Date:</span> <span className="text-dark">{details.expiration || 'N/A'}</span></p>

                                </div>
                                <div className="col-md-6">
                                    <div className="text-end"> <button className="btn--OliveDrab">{details.permittype}</button></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <div className="input_grp mt-4">
            <div className="input_itm w-100">
              <label className="d-block">Permit Type:</label>
              <div className="out_txt">{details.permittype}</div>
            </div>
          </div> */}
                    {/* <div className="input_grp mt-4">
            <div className="input_itm w-100">
              <label className="d-block">Status:</label>
              <div className="out_txt">{details.status}</div>
            </div>
          </div> */}
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Description:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.description) }}
                            ></p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Owner:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.owner) }}
                            ></p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Contractor:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.contractor) }}
                            ></p>
                        </div>
                    </div>
                    {/* <div className="input_grp mt-4">
            <div className="input_itm w-100">
              <label className="d-block">Expiration Date:</label>
              <div className="out_txt">{details.expiration || 'N/A'}</div>
            </div>
          </div> */}
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Permit Application Common:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.permit_application_common) }}
                            ></p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Permit Tracking Common:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.permit_tracking_common) }}
                            ></p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Floating Residence Info:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.floating_residence_info) }}
                            ></p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Construction Valuation Info:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.construction_valuation_info) }}
                            ></p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Building Code Info:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.building_code_info) }}
                            ></p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Ground Disturbance:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.ground_disturbance) }}
                            ></p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Tenant Relocation Assistance:</h4>
                            <p className="txt mb-0">{details.tenant_relocation_assistance || 'N/A'}</p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Contractor Disclosure:</h4>
                            <p className="txt mb-0">{details.contractor_disclosure || 'N/A'}</p>
                        </div>
                    </div>
                    <div className="input_grp mt-4">
                        <div className="input_itm w-100">
                            <h4 className="fz20 DMSans_bold">Application Info:</h4>
                            <p
                                className="txt mb-0"
                                dangerouslySetInnerHTML={{ __html: formatNewlines(details.applicationinfo) }}
                            ></p>
                        </div>
                    </div>
                </>
            )}
        </div>
        <Loader type={"tableLines"} />
    </>
}


export default NotificationDetails