

// import React, { useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";


// const ProfileList = ({profileList, handleFinalCase}) => {


// console.log("------------------>",profileList)
//     return (
//         <>
//             <h6 className="boldtxt_on mt30 text-center">Welcome! First, let’s start by looking up your business</h6>
//             <p>We’ll use this information to help you claim your
//                 Buildfolio profile.</p>
//             <h6 className="boldtxt_on mt30 text-center">Verify Your Profile</h6>
//             <div className="onboard__table">
//                 <table>
//                     <thead>
//                         <tr>
//                             <th width="15%"></th>
//                             <th>Lic. ID</th>
//                             <th width="42%">Lic. Type</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {profileList?.map((ele) => {
//                             return <tr>
//                                 <td>
//                                     <input type="checkbox" />
//                                 </td>
//                                 <td>{ele?.id}</td>
//                                 <td>{ele?.contractorlicensetypecodedesc}</td>
//                             </tr>
//                         })}


//                     </tbody>
//                 </table>
//             </div>
//             <button className="btn_cus mt-5">Continue</button>
//         </>
//     );
// };

// export default ProfileList;



import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import alert from "../../../utils/alert";

const ProfileList = ({ profileList, handleFinalCase }) => {
    const [checkedIds, setCheckedIds] = useState([]);

    const handleCheckboxChange = (id) => {
        if (checkedIds.includes(id)) {
            setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
        } else {
            setCheckedIds([...checkedIds, id]);
        }
    };

    const handleContinue = () => {
        // Pass the checkedIds to the parent component or handle logic heres
   
        // Example: handleFinalCase(checkedIds);
        if (profileList.length === checkedIds.length) {

            handleFinalCase(checkedIds)
        } else {
            alert("error", "Please select all profiles to continue")
        }
    };

    return (
        <>
            <h6 className="boldtxt_on mt30 text-center">Welcome! First, let’s start by looking up your business</h6>
            <p>We’ll use this information to help you claim your Buildfolio profile.</p>
            <h6 className="boldtxt_on mt30 text-center">Verify Your Profile</h6>
            <div className="onboard__table">
                <table>
                    <thead>
                        <tr>
                            <th width="15%"></th>
                            <th>Lic. ID</th>
                            <th width="42%">Lic. Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {profileList?.map((ele) => (
                            <tr key={ele?.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange(ele.id)}
                                        checked={checkedIds.includes(ele.id)}
                                    />
                                </td>
                                <td>{ele?.contractorlicensenumber}</td>
                                <td>{ele?.contractorlicensetypecodedesc}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button className="btn_cus mt-5" onClick={handleContinue}>
                Continue
            </button>
        </>
    );
};

export default ProfileList;
