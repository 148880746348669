import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/SharedData";
import Axios from "../../axios/axiosInstance";
import alert from "../../utils/alert";
import { useForm } from "react-hook-form";

const CustomModal = ({
  isBulkSMS,
  action,
  queries,
  deviceType,
  handleChange,
  type,
  show,
  onHide,
  handleClose,
  reportType,
  modalBodyText,
  addGroup
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });



  const { contractorDetails } = useAppContext();
  const navigate = useNavigate();
  let modalContent;

  const handleAddApplicantsCounts = async () => {
    await Axios.put(`posts/${contractorDetails.id}/send-message/${modalBodyText.id}`)
      .then((res) => {
        onHide()
        // alert("success", "Post deleted successfully");
      })
      .catch((err) => {
        err.response && alert("error", err.response.data.error);
      })
      .finally(() => {
        onHide()
      });
  };





  switch (type) {

    case "createGroup":
      modalContent = (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <div className="modal_body px-4">

            <h3 className="modal_ttl text-center py-3 mb-0">Create Group</h3>

            <form onSubmit={handleSubmit(async (data) => {
              try {
                await addGroup(data); // Call the addGroup function
                reset();
                onHide(); // Hide the modal if form is successfully submitted
              } catch (error) {
                console.error("Error creating group:", error);
              }
            })}>
              <div className="input_grp">
                <div className="input_itm w-100">
                  <label htmlFor="grpname" className="d-block">Group Name</label>
                  <input type="text" className="inputFill border-0 p-0" id="grpname" placeholder="Enter Group Name"   {...register("name", {
                    required: "Group Name is required"
                  })} />
                </div>
              </div>
              {errors.name && <span className="text-danger">{errors.name.message}</span>}
              <div className="footer_modal py-3 mt-2 d-flex pb-4 justify-content-center">
                <button type="submit" className="btn_cus btnMinW me-3 py-2">
                  Save
                </button>
                <button type="button" onClick={() => { reset(); handleClose() }} className="btn_cus br_white_btn btnMinW py-2">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      );
      break;
    case "paymentConfirm":
      modalContent = (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <div className="modal_body">

            <div className="text-center">   <h3 className="modal_ttl text-center py-3 mb-0 ">Proceed To Pay</h3>
              <h3 className="modal_ttl text-center py-3 mb-0 bluedrk">${modalBodyText}</h3>
              <p className="text-center mdltxt graytxt mb-0 px-sm-5 px-2">Paid Amount</p></div>
            <div className="footer_modal py-3 mt-2 d-flex justify-content-center">
              <button onClick={() => handleChange()} className="btn_cus btnMinW me-3 py-2">
                OK
              </button>
              <button onClick={() => handleClose()} className="btn_cus br_white_btn btnMinW py-2">
                No
              </button>
            </div>
          </div>

        </Modal>
      );
      break;
    case "delete":
      modalContent = (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <div className="modal_body">
            <div className="text-center">
              {" "}
              <img src="img/modal_delete.svg" alt="modal delete icon" />
            </div>
            <h3 className="modal_ttl text-center py-3 mb-0">Delete!</h3>
            <p className="text-center mdltxt graytxt mb-0 px-sm-5 px-2">
              {modalBodyText ? modalBodyText : "Are you sure you want to Delete this post?"}
            </p>
            <div className="footer_modal py-3 mt-2 d-flex justify-content-center">
              <button onClick={() => handleChange()} className="btn_cus btnMinW me-3 py-2">
                OK
              </button>
              <button onClick={() => handleClose()} className="btn_cus br_white_btn btnMinW py-2">
                No
              </button>
            </div>
          </div>
        </Modal>
      );
      break;
    case "signup":
      modalContent = (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header className="border-0"></Modal.Header>
          <div className="modal_body p-3 pt-0">
            <div className="text-center">
              {modalBodyText?.img &&
                <img src={modalBodyText?.img} alt="modal alert icon" className="mb-3" />
              }
            </div>
            <h3 className="modal_ttl text-center mb-0">{modalBodyText?.header1}</h3>
            <h4 className="modal_sub_ttle text-center mb-0 mt-4 ">{modalBodyText?.header2}</h4>
            <p className="text-center fz14 mdltxt graytxt mb-0">
              {modalBodyText?.body1}
            </p>

            <p className="text-center fz14 graytxt mt-2 mb-0">
              {modalBodyText?.body2}
            </p>
            <div className="footer_modal py-3 mt-2 d-flex justify-content-center">
              <button onClick={() => handleChange()} className="btn_cus btnMinW me-3 py-2">
                OK
              </button>
              {modalBodyText.header1 !== "Congratulations!" &&
                <button onClick={() => {
                  if (action) {
                    action()
                    return
                  }
                  handleClose()

                }} className="btn_cus br_white_btn btnMinW py-2">
                  No
                </button>}
            </div>
          </div>
        </Modal>
      );
      break;
    case "postLimitAlert":
      modalContent = (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <div className="modal_body p-3 pt-0">
            <div className="modal_ttl text-center">Alert</div>
            <p className="my-4 text-center modal__txt--bg p-4 fz14"> You can only add 1 post per week if you're not
              subscribed. Please note that this post will
              automatically expire after 1 week.</p>
            <div className="text-center">
              <button onClick={() => {
                handleChange()
                onHide()
              }} className="btn_cus btnMinW mx-auto px-5 py-2">
                OK
              </button>
            </div>
          </div>

        </Modal>
      );
      break;
    case "info":
      modalContent = (
        <Modal centered show={show} onHide={onHide} className="infoModal">
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <div className="modal_body px-4">
            {!contractorDetails ? (
              <div className="form_txtview p-4">
                <p className="mb-2 text-dark">Hi, </p>
                <p className="text-dark">
                  Someone from Buildfolio wants to hire you. Respond to this message if interested
                </p>

                <p className="mt-3 text-dark mb-0">Thank you!</p>
                <p className="mt-1 text-dark mb-0">[Buildfolio Team]</p>
              </div>
            ) : (
              <>
                <h3 className="modal_ttl text-center">Job Details</h3>
                <div className="form_txtview p-4">
                  <p className="mb-2 text-dark">Hi, </p>
                  <p className="text-dark">
                    [{contractorDetails?.businessname || "N/A"}] has a job available in your area. Reply
                    if you're interested.
                  </p>
                  <p className="mb-1">
                    <span>Job Description:</span>{" "}
                    <span className="text-dark">[{contractorDetails.description || "N/A"}]</span>{" "}
                  </p>
                  <p className="mb-1">
                    <span>Type:</span>{" "}
                    <span className="text-dark">[{contractorDetails.type || "N/A"}]</span>{" "}
                  </p>
                  <p className="mb-1">
                    <span>Zip:</span> <span className="text-dark"> [{contractorDetails.zip}] </span>{" "}
                  </p>
                  <p className="mb-1">
                    <span>When:</span>{" "}
                    <span className="text-dark"> [{contractorDetails.time || "N/A"}] </span>{" "}
                  </p>
                  <p className="mb-1">
                    <span>Duration:</span>{" "}
                    <span className="text-dark"> [ {contractorDetails?.duration || "N/A"}] </span>{" "}
                  </p>

                  <p className="mt-3 text-dark mb-0">Thank you!</p>
                  <p className="mt-1 text-dark mb-0">[Buildfolio Team]</p>
                </div>
              </>
            )}
            <div className="footer_modal py-3 mt-2">
              <a
                onClick={() => {
                  // onHide()
                  if (contractorDetails) {
                    handleAddApplicantsCounts();
                  }

                }}
                style={{ textDecoration: "none" }}
                href={`sms:${isBulkSMS?modalBodyText?.phonenumber?.join(","):modalBodyText?.phonenumber}?&body=${!contractorDetails ?
                  encodeURIComponent(`
    Hi, 
Someone from Buildfolio wants to hire you. Respond to this message if interested ?

Thank you!
Buildfolio Team`)
                  : encodeURIComponent(`
Hi,  
${contractorDetails?.businessname || "N/A"} has a job available in your area. Reply if you're interested.

  Job Description: ${contractorDetails.description || "N/A"}
  Type: ${contractorDetails.type || "N/A"}
  Zip: ${contractorDetails.zip || "N/A"}
  When: ${contractorDetails.time || "N/A"}
  Duration: ${contractorDetails.duration || "N/A"}

  Thank you!
  Buildfolio Team`)
                  }`}
              >
                <button onClick={() => { setTimeout(() => { onHide() }, 500) }} className="btn_cus btnMinW mx-auto py-2">Send SMS</button>
              </a>
            </div >
          </div >
        </Modal >
      );
      break;


    // Add more cases for additional modal types here if needed

    default:
      modalContent = (

        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <div className="modal_body">
            <div className="text-center">
              <img src="img/alert.svg" alt="modal alert icon" />
            </div>
            <h3 className="modal_ttl text-center py-3 mb-0 redtxt">Alert!</h3>
            <p className="text-center mdltxt graytxt mb-0 px-sm-5 px-2">
              {modalBodyText
                ? modalBodyText
                : "In order to access this feature you need to Sign Up!"}
            </p>
            <div className="footer_modal py-3 mt-2">
              <button
                onClick={() => {
                  if (type === "status") {
                    handleChange();
                  } else {
                    // Initialize an array to store query parameters
                    const queryParams = [];

                    // Add query parameters only if they have values
                    if (queries?.description)
                      queryParams.push(`description=${queries.description}`);
                    if (queries?.zip) queryParams.push(`zip=${queries.zip}`);
                    if (queries?.language) queryParams.push(`language=${queries.language}`);
                    if (queries?.type) queryParams.push(`type=${queries.type}`);
                    if (queries?.time) queryParams.push(`time=${queries.time}`);
                    if (queries?.duration) queryParams.push(`duration=${queries.duration}`);

                    // Join the query parameters with '&' and prepend with '?'
                    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

                    // Navigate with the constructed query string
                    navigate(`/login${queryString}`, {
                      state: { isSMSclicked: modalBodyText.includes("SMS") ? true : false }
                    });
                  }
                }}
                className="btn_cus btnMinW mx-auto py-2"
              >
                OK
              </button>
            </div>
          </div>
        </Modal>
      );
  }

  return <>{modalContent}</>;
};

export default CustomModal;
