// //   return (
// //     <form onSubmit={handleSubmit}>
// //       <CardElement />
// //       <button type="submit" disabled={!stripe}>
// //         Pay
// //       </button>
// //     </form>
// //   );
// // };

// // export default PaymentForm;


// // PaymentForm.js

// import React, { useEffect, useState } from 'react';
// import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
// import stripePromise from './stripe';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Axios from '../../axios/axiosInstance';
// import SavedCards from './SavedCards';
// import alert from '../../utils/alert';
// import useLoader from '../../components/loaders/useLoader';
// import { useAppContext } from '../../context/SharedData';
// // import './PaymentForm.css'; // Import CSS file for styling

// const PaymentForm = () => {
//   const { setRefreshPage, refreshPage, } = useAppContext()
//   const { showLoader, hideLoader, Loader, isLoading } = useLoader();
//   const [name, setName] = useState("")
//   const navigate = useNavigate()
//   const stripe = useStripe();
//   const elements = useElements();
//   const location = useLocation()
//   const priceId = location?.state?.priceKey || null
//   const price = location?.state?.price || null

//   console.log("huahuahuah", location)

//   useEffect(() => {
//     if (!priceId || !price) {
//       alert("error", "Session expired!")
//       navigate("/choose-plan")
//     }
//   }, [])

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
//       if (name.trim().length === 0) {
//         alert("error", "Please enter card holder name")
//         return
//       }
//       hideLoader(); // Hide loader if Stripe or elements are not ready
//       return;
//     } else {
//       showLoader(); // Show loader when form submission begins

//     }

//     const cardElement = elements.getElement(CardNumberElement);

//     try {
//       const { error, paymentMethod } = await stripe.createPaymentMethod({
//         type: 'card',
//         card: cardElement,
//       });

//       if (error) {
//         // alert("error", "Payment failed!");
//         console.error('Error:', error);
//         hideLoader(); // Hide loader on payment failure
//       } else {
//         console.log('PaymentMethod:', paymentMethod);

//         // Send paymentMethod.id to your server to complete the payment
//         const customerResponse = await Axios.post(`plans/subscribe`, {
//           priceId: priceId || null,
//           payment_id: paymentMethod.id,
//         });

//         if (customerResponse.status === 200) {
//           setRefreshPage(true)
//           alert("success", "Payment successful");
//           navigate("/plans")
//         } else {
//           alert("error", "Payment failed!");
//         }
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert("error", "Payment failed!");
//     } finally {
//       hideLoader(); // Ensure loader is hidden after the operation completes (success or failure)
//     }
//   };


//   return (
//     <>
//       <Loader />
//       <div className="heading mb-3"><img onClick={() => navigate(-1)} src="img/backicon.svg" alt="back icon" className='me-2 pointer' /> Add Card Details</div>
//       <form className="payment-form" onSubmit={handleSubmit}>
//         <div className="row">
//           <div className="col-xl-4 col-md-6">
//             <div className="input_grp">
//               <label htmlFor="card-name">Card holder name</label>
//               <input value={name} onChange={(e) => setName(e.target.value)} type="text" id='card-name' className='inputFill border-0 p-0 fz14' placeholder='Enter name' />

//             </div>
//             <div className="input_grp mt-4">
//               <label htmlFor="card-number"> Card Number</label>
//               <CardNumberElement id="card-number" className='fz14' options={{
//                 style: {
//                   base: {
//                     fontSize: '16px',
//                     color: '#424770',
//                     '::placeholder': {
//                       color: '#aab7c4',
//                     },
//                   },
//                   invalid: {
//                     color: '#9e2146',
//                   },
//                 },
//               }} />
//             </div>
//             <div className="row mt-4">
//               <div className="col-6">
//                 <div className="input_grp">
//                   <label htmlFor="expiry">
//                     Expiry Date
//                   </label>
//                   <CardExpiryElement id="expiry" className='fz14' options={{
//                     style: {
//                       base: {
//                         fontSize: '16px',
//                         color: '#424770',
//                         '::placeholder': {
//                           color: '#aab7c4',
//                         },
//                       },
//                       invalid: {
//                         color: '#9e2146',
//                       },
//                     },
//                   }} />
//                 </div>
//               </div>
//               <div className="col-6">
//                 <div className="input_grp">
//                   <label htmlFor="cvc">
//                     CVC
//                   </label>
//                   <CardCvcElement id="cvc" options={{
//                     style: {
//                       base: {
//                         fontSize: '16px',
//                         color: '#424770',
//                         '::placeholder': {
//                           color: '#aab7c4',
//                         },
//                       },
//                       invalid: {
//                         color: '#9e2146',
//                       },
//                     },
//                   }} />
//                 </div>
//               </div>
//             </div>
//             <button className="btn_cus mt-5" type="submit" disabled={!stripe}>
//               Add
//             </button>
//             <div className="heading mb-3 mt-4">Saved Cards</div>
//             {/* <div className="input_grp mt-3">
//               <div className="bg__light__grey p-3 d-flex align-items-center justify-content-between">
//                 <div className="savecard d-flex align-items-center">
//                   <img src="img/Cards--icon--circle.svg" alt="Cards--icon--circle" className='me-3' />
//                   <div className="d-inline"><label className='d-block'>Debit Card </label><span className='d-block fz14 graytxt'>**** **** **** 4569</span></div>
//                 </div>
//                 <div className="dlt pointer">
//                   <img src="img/delete--orange.svg" alt="delete_icon" />
//                 </div>
//               </div>
//             </div> */}
//             <SavedCards priceId={priceId} price={price} />
//           </div>
//         </div>

//       </form>
//     </>

//   );
// };

// export default PaymentForm;


import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import Axios from '../../axios/axiosInstance';
import alert from '../../utils/alert';
import useLoader from '../../components/loaders/useLoader';
import { useAppContext } from '../../context/SharedData';
import SavedCards from './SavedCards';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentForm = () => {
  const { setRefreshPage, refreshPage } = useAppContext();
  const { showLoader, hideLoader, Loader } = useLoader();
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const priceId = location?.state?.priceKey || null;
  const price = location?.state?.price || null;

  useEffect(() => {
    if (!priceId || !price) {
      alert('error', 'Session expired!');
      navigate('/choose-plan');
    }
  }, []);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   if (name.trim().length === 0) {
  //     alert('error', 'Please enter card holder name');
  //     return;
  //   }

  //   if (!stripe || !elements) {
  //     hideLoader();
  //     return;
  //   }

  //   showLoader();

  //   const cardElement = elements.getElement(CardNumberElement);

  //   try {
  //     const { error, paymentMethod } = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: cardElement,
  //     });

  //     if (error) {
  //       console.error('Error:', error);
  //       hideLoader();
  //       alert('error', error.message); // Display Stripe-specific error message
  //     } else {
  //       console.log('PaymentMethod:', paymentMethod);

  //       const customerResponse = await Axios.post(`plans/subscribe`, {
  //         priceId: priceId || null,
  //         payment_id: paymentMethod.id,
  //       });

  //       if (customerResponse.status === 200) {
  //         setRefreshPage(true);
  //         alert('success', 'Payment successful');
  //         navigate('/plans');
  //       } else {

  //         alert('error', 'Payment failed!');
  //       }
  //     }
  //   } catch (err) {
  //     console.log("sssssssssssssssssssssssssssssssssssssssssssssss--->",err)
  //     alert('error', "ok");
  //   } finally {
  //     hideLoader();
  //   }
  // };
  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   // Check if cardholder name is entered
  //   if (name.trim().length === 0) {
  //     alert('error', 'Please enter card holder name');
  //     return;
  //   }

  //   // Check if Stripe and Elements are ready
  //   if (!stripe || !elements) {
  //     hideLoader();
  //     return;
  //   }

  //   showLoader(); // Show loader while processing

  //   try {
  //     // Get the CardElement from Elements
  //     const cardElement = elements.getElement(CardNumberElement);

  //     // Create a payment method using Stripe
  //     const { error, paymentMethod } = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: cardElement,
  //     });

  //     if (error) {
  //       console.error('Stripe Error:', error);
  //       hideLoader();
  //       alert('error', error.message || 'Payment failed');
  //     } else {
  //       console.log('PaymentMethod:', paymentMethod);

  //       // Send paymentMethod.id to your server to complete the payment
  //       const customerResponse = await Axios.post(`plans/subscribe`, {
  //         priceId: priceId || null,
  //         payment_id: paymentMethod.id,
  //       });

  //       // Handle response from server
  //       if (customerResponse?.status === 200) {
  //         setRefreshPage(true); // Refresh page or update context state
  //         alert('success', 'Payment successful');
  //         navigate('/plans'); // Navigate to success page
  //       } else {
  //         alert('error', 'Payment failed! Please try again.');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Caught Error:', error);
  //     alert('error', 'Payment failed! Please try again later.');
  //   } finally {
  //     hideLoader(); // Hide loader after processing
  //   }
  // };



  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if cardholder name is entered
    if (name.trim().length === 0) {
      alert('error', 'Please enter the cardholder name');
      return;
    }

    // Check if Stripe and Elements are ready
    if (!stripe || !elements) {
      alert('error', 'Payment system is not ready. Please try again later.');
      return;
    }

    showLoader(); // Show loader while processing

    try {
      // Get the CardElement from Elements
      const cardElement = elements.getElement(CardNumberElement);

      // Create a payment method using Stripe
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {

        alert('error', `Stripe error: ${error.message || 'Payment failed'}`);
        return;
      }

    

      // Send paymentMethod.id to your server to complete the payment
      await Axios.post('subscription/buy', {
        priceId: priceId || null,
        payment_id: paymentMethod.id,
      }).then((res) => {
        if (res?.status === 200) {
          setRefreshPage(true); // Refresh page or update context state
          alert('success', 'Payment successful');
          navigate('/plans'); // Navigate to success page
        } else {
          console.error('INSIDE Caught Error:', error);
          alert('error', `Server error: ${res?.data?.message || 'Payment failed! Please try again.'}`);
        }
      }).catch((err) => {
        alert("error", err.response.data.error)
        console.error('errrr:', err.response.data.error);
      })

    } catch (error) {
      console.error('Caught Error:', error.message);
      alert('error', 'An unexpected error occurred. Please try again later.');
    } finally {
      hideLoader(); // Hide loader after processing
    }
  };


  return (
    <>
      <Loader />
      <div className="heading mb-3">
        <img onClick={() => navigate(-1)} src="img/backicon.svg" alt="back icon" className="me-2 pointer" /> Add Card Details
      </div>
      <form className="payment-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="input_grp">
              <label htmlFor="card-name">Card holder name</label>
              <input value={name} onChange={(e) => setName(e.target.value)} type="text" id="card-name" className="inputFill border-0 p-0 fz14" placeholder="Enter name" />
            </div>
            <div className="input_grp mt-4">
              <label htmlFor="card-number">Card Number</label>
              <CardNumberElement id="card-number" className="fz14" options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }} />
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <div className="input_grp">
                  <label htmlFor="expiry">Expiry Date</label>
                  <CardExpiryElement id="expiry" className="fz14" options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }} />
                </div>
              </div>
              <div className="col-6">
                <div className="input_grp">
                  <label htmlFor="cvc">CVC</label>
                  <CardCvcElement id="cvc" className="fz14" options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }} />
                </div>
              </div>
            </div>
            <button className="btn_cus mt-5" type="submit" disabled={!stripe}>
              Pay
            </button>
            <div className="heading mb-3 mt-4">Saved Cards</div>
            <SavedCards priceId={priceId} price={price} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PaymentForm;
