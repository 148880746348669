// export const firebaseConfig = {
//     apiKey: "AIzaSyDIsf2doY6f8dtObSnenKE-rEOJStuyCt4",
//     authDomain: "buildfol.firebaseapp.com",
//     projectId: "buildfol",
//     storageBucket: "buildfol.appspot.com",
//     messagingSenderId: "992200248257",
//     appId: "1:992200248257:web:30783873a7dc29bdfc3d40",
//     measurementId: "G-DJ3BFQNZ37",
//   };



//Rajat sir

// export const firebaseConfig = {
//   apiKey: "AIzaSyD2_FD4gqPJUg-SQdGpYQ0mdtC1gRXLloY",
//   authDomain: "buildfolio-aeaa7.firebaseapp.com",
//   projectId: "buildfolio-aeaa7",
//   storageBucket: "buildfolio-aeaa7.appspot.com",
//   messagingSenderId: "685825138410",
//   appId: "1:685825138410:web:c3a993535e7c5c17b84193",
//   measurementId: "G-PREJL6KH8F"
// };


export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
