import React from "react";
import { formatDate } from "../../utils/dateConverter";

const BreadCrumbsDiv = ({ contractorDetails }) => {
 
  return (
    <>
      <div className="jobDisC_grp mt-3 d-flex flex-column">
        <div className="jobDisC d-flex drBlue">
          <div className="jobDisC-item">
            <span>Date:</span>
            <span>{formatDate(contractorDetails?.created_at)}</span>
          </div>

          <div className="jobDisC-item">
            <span>Type:</span>
            <span>{contractorDetails?.type || "N/A"}</span>
          </div>
          <div className="jobDisC-item">
            <span>Zip Code:</span>
            <span>{contractorDetails?.zip || "N/A"}</span>
          </div>
          <div className="jobDisC-item">
            <span>Time:</span>
            <span>{contractorDetails?.time || "N/A"}</span>
          </div>
          <div className="jobDisC-item">
            <span>Duration:</span>
            <span>{contractorDetails?.duration || "N/A"}</span>
          </div>
        </div>
        <div className="jobDisC d-block  lgBlue">
          <div className="jobDisC-item  w-100">
            <span>Job Des:</span>
            <span>
              {contractorDetails?.description || "N/A"}
              {/* {contractorDetails?.description
                ? contractorDetails.description.length > 30
                  ? contractorDetails.description.slice(0, 30) + ".."
                  : contractorDetails.description
                : "N/A"} */}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreadCrumbsDiv;
