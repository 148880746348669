

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "../../../axios/axiosInstance";
import alert from "../../../utils/alert";
import CustomModal from "../../../components/common/CustomModal";
import { JOB_POST_QUERY_PARAMS } from "../../../config/queryParams";
import { getDeviceType } from "../../../utils/deviceDetection";
const Signup = () => {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  

  const navigate = useNavigate();
  const location = useLocation();

  let device_type=getDeviceType()
  const queryParams = new URLSearchParams(location.search);
  const profileList=location?.state?.profileList || []

  const [view, setView] = useState(false);
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!watch("isWebsiteAvailable")) {
      trigger("website")
    }
  }, [watch("isWebsiteAvailable")])

  const onSubmit = (data) => {
 
    Axios.post("signup", {
      device_type:device_type,
      device_token:localStorage.getItem("FCM_TOKEN") || null,
      ...(profileList.length>0 && { license_id_arr: profileList|| [] }),
      ...(data.email && { email: data.email }),
      ...(data.password && { password: data.password }),
      ...(data.phone && { phone_number: data.phone }),
      ...(data.fullName && { name: data.fullName }),
      ...(data.alternatePhone && { alternate_phone_number: data.alternatePhone }),
      ...(localStorage.getItem("l_id") && { license_id: localStorage.getItem("l_id") }),
      ...(watch("isWebsiteAvailable") && { website: data.website }),
    })
      .then((res) => {
        if (res) {
          setShow(true)
          localStorage.setItem("user_token", res.data.data.token);
          localStorage.removeItem("b_name")
        }
      })
      .catch((err) => err.response && alert("error", err.response.data.error))
  };

  return (
    <>
      <h6 className="boldtxt_on mt30 mb30 text-center">Upgrade Your Free Business Account</h6>
      <p className="text-center">Are you <b className="text-dark">{localStorage.getItem("b_name")}</b> or you want to add additional name?</p>
      {location?.state?.isSMSclicked &&
        <div className="noti_notEl d-flex mb-4">
          <img src="img/notEqual.svg" alt="notEqual" />
          <p className="mb-0 ms-2 text-center">Please create an account in order to send text messages
            directly to other contractors.</p>
        </div>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="min_hform">
          <div className="input_grp ">
            <div className="d-flex align-items-start">
              <img src="img/username.svg" alt="phone" className="icon_input me-2 mt-1" />
              <div className="input_itm w-100">
                <label htmlFor="phone" className="d-block">
                  Additional Name
                  {/* <sup>*</sup> */}
                </label>
                <input
                  type="text"
                  id="phone"
                  className="inputFill border-0"
                  placeholder="Enter Additional Name"
                  maxLength={50}
                  {...register("fullName", {
                    // required: "Full Name is required",
                  })}
                />
              </div>
            </div>
          </div>
          {errors.fullName && <span className="text-danger">{errors.fullName.message}</span>}

          <div className="input_grp mt30">
            <div className="d-flex align-items-start">
              <img src="img/phone.svg" alt="phone" className="icon_input me-2 mt-1" />
              <div className="input_itm w-100">
                <label htmlFor="phone" className="d-block">
                  Mobile No.<sup>*</sup>
                </label>
                <input
                  type="text"
                  id="phone"
                  className="inputFill border-0"
                  placeholder="Enter Phone Number"
                  maxLength={10}
                  {...register("phone", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid phone number",
                    },
                    minLength: {
                      value: 10,
                      message: "Phone no. must be 10 digits",
                    },
                    maxLength: {
                      value: 10,
                      message: "Phone no. must be 10 digits",
                    },
                    validate: (value) =>
                      value.trim().length === 10 || "Password must be at least 10 characters long",
                  })}
                />
              </div>
            </div>
          </div>
          {errors.phone && <span className="text-danger">Please enter a valid phone number</span>}
          <div className="input_grp mt30">
            <div className="d-flex align-items-start">
              <img src="img/phone.svg" alt="phone" className="icon_input me-2 mt-1" />
              <div className="input_itm w-100">
                <label htmlFor="phone" className="d-block">
                  Additional Mobile No.
                </label>
                <input
                  type="text"
                  id="phone"
                  className="inputFill border-0"
                  placeholder="Enter Additional Phone Number"
                  maxLength={10}
                  {...register("alternatePhone", {
                    validate: {
                      noValue: (value) => {
                        if (!value.trim()) return true; // Skip validation if no value entered
                        // Apply other validations if value is entered
                        if (!/^[0-9]{10}$/.test(value)) {
                          return "Please enter a valid phone number";
                        }
                        return true;
                      },
                    },
                  })}
                />
              </div>
            </div>
          </div>
          {errors.alternatePhone && <span className="text-danger">Please enter a valid phone number</span>}
          <div className="input_grp mt30">
            <div className="d-flex align-items-start">
              <img src="img/email.svg" alt="email" className="icon_input me-2 mt-1" />
              <div className="input_itm w-100">
                <label htmlFor="email" className="d-block">
                  Email Address<sup>*</sup>
                </label>
                <input
                  type="email"
                  id="email"
                  className="inputFill border-0"
                  placeholder="Enter Email Address"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
              </div>
            </div>
          </div>
          {errors.email && <span className="text-danger">Please enter a valid email address</span>}
          <div className="input_grp mt30">
            <div className="d-flex align-items-start">
              <img src="img/password.svg" alt="password" className="icon_input me-2 mt-1" />
              <div className="input_itm w-100">
                <label htmlFor="password" className="d-block">
                  Password
                </label>
                <div className="position-relative">
                  <input
                    type={view ? "" : "password"}
                    id="password"
                    className="inputFill border-0"
                    placeholder="Enter Password"
                    {...register("password", {
                      required: "Password is required",
                      validate: (value) =>
                        value.trim().length >= 8 || "Password must be at least 8 characters long",
                    })}
                  />
                  {view ? (
                    <img
                      onClick={() => setView(false)}
                      src="img/eye.svg"
                      alt="eye"
                      className="position-absolute  pwd_icon"
                    />
                  ) : (
                    <img
                      onClick={() => setView(true)}
                      src="img/eyeClose.svg"
                      alt="eye"
                      className="position-absolute  pwd_icon"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {errors.password && (
            <span className="text-danger">Please enter a password with at least 8 characters</span>
          )}
          <div className="mt-2">
            <div className="d-flex align-items-center">
              <input
                className="inputFill border-0 me-2 max-wf"
                {...register("isWebsiteAvailable")}
                type="checkbox"
                id="checkboxWeb"
              />    
              <label htmlFor="checkboxWeb" >
                Do you have a website?
              </label>

            </div>
          </div>
          {watch("isWebsiteAvailable") &&
            <div className="input_grp mt30">
              <div className="d-flex align-items-start">
                <img src="img/webIcon.svg" alt="phone" className="icon_input me-2 mt-1" />
                <div className="input_itm w-100">
                  <label htmlFor="phone" className="d-block">
                    Website
                  </label>
                  <input
                    className="inputFill border-0"
                    placeholder="yourwebsite.com"
                    {...register("website", {
                      required: watch("isWebsiteAvailable") ? "Please enter website" : false,
                      pattern: {
                        value:
                          /^(?!www\.|https:\/\/)([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/,
                        message: "Please enter a valid website URL",
                      },
                    })}
                    type="text"
                  />
                </div>
              </div>
            </div>
          }
          {errors.website && <span style={{ color: "red" }}>{errors.website.message}</span>}
        </div>
        <button type="submit" className="btn_cus mt30">
          Sign Up
        </button>
        <p className="text-center mt-3">
          Already have an account?{" "}
          <Link to={`/login?${JOB_POST_QUERY_PARAMS(queryParams)}`} className="bluedrk">
            Log in
          </Link>
        </p>
      </form>
      <CustomModal onHide={() => setShow(true)} handleChange={function action() { navigate(`/login?${JOB_POST_QUERY_PARAMS(queryParams)}`) }} show={show} type={"signup"} handleClose={() => setShow(true)} modalBodyText={{
        img: "img/congratulations.svg", header1: "Congratulations!", body1: "You are one step closer to success!", body2: "Your account status is: Pending Verification. You’ll be notified once it’s completed."
      }} />
    </>
  );
};

export default Signup;
