
export const JOB_POST_QUERY_PARAMS = (queryParams) => {
    const zip = queryParams.get("zip") || "";
    const duration = queryParams.get("duration") || "";
    const time = queryParams.get("time") || "";
    const language = queryParams.get("language") || "";
    const type = queryParams.get("type") || "";
    const description = queryParams.get("description") || "";

    return `description=${description}&zip=${zip}&language=${language}&type=${type}&time=${time}&duration=${duration}`
}