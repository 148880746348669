import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { formatDate } from "../../utils/dateConverter";

const ToolTip = ({ allPostData }) => {
  return (
    <>
      {allPostData?.length>0 ? allPostData?.map((ele, index) => (
        <React.Fragment key={index}>
          <OverlayTrigger
            placement="auto-start"
            overlay={
              <Tooltip>
                {
                  <div>
                    <div className="toolTip_itm mb-2">
                      <p className="mb-0 fz12 d-flex align-items-center justify-content-between">
                        <span>Job Description:</span>{" "}
                        <span className="ms-2 actionTooltip">Active</span>
                      </p>
                      <div className="fz12 text-dark mt-1">
                        {ele?.description ? ele?.description : "N/A"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="toolTip_itm mb-2">
                          <p className="mb-0 fz12">Contractor Type:</p>
                          <div className="fz12 text-dark"> {ele?.type || "N/A"}</div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="toolTip_itm mb-2">
                          <p className="mb-0 fz12">Duration (Days):</p>
                          <div className="fz12 text-dark"> {ele?.duration || "N/A"}</div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="toolTip_itm mb-2">
                          <p className="mb-0 fz12">Time:</p>
                          <div className="fz12 text-dark"> {ele?.time || "N/A"}</div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="toolTip_itm mb-2">
                          <p className="mb-0 fz12">Applicants:</p>
                          <div className="fz12 text-dark"> {ele.total_applicants || 0}</div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="toolTip_itm mb-2">
                          <p className="mb-0 fz12">Language:</p>
                          <div className="fz12 text-dark"> {ele?.language || "N/A"}</div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="toolTip_itm mb-2">
                          <p className="mb-0 fz12">Date:</p>
                          <div className="fz12 text-dark">
                            {formatDate(ele?.created_at) || "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </Tooltip>
            }
          >
            <Dropdown.Item eventKey={ele.id}>
              <b className="fz12">{formatDate(ele?.created_at)}</b>
              <span className="graytxt ms-2">{ele.description}</span>
            </Dropdown.Item>
          </OverlayTrigger>

          <Dropdown.Divider />
        </React.Fragment>
      )):  <Dropdown.Item disabled>
      {/* <b className="fz12">{formatDate(ele?.created_at)}</b> */}
      <span className="graytxt ms-2">No Post Yet</span>
    </Dropdown.Item>}
    </>
  );
};

export default ToolTip;
