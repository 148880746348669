// import axios from "axios";
// import alert from "../utils/alert";
// import { logout } from "../utils/logout";

// const Axios = axios.create({
//   baseURL: process.env.REACT_APP_BUILDFOLIO_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// let isInternetAlertShown = false;
// let isGenericErrorAlertShown = false;

// const showInternetAlert = () => {
//   if (!isInternetAlertShown) {
//     alert("error", "No internet connection. Please check your connection.");
//     isInternetAlertShown = true;
//   }
// };

// const showGenericErrorAlert = (error) => {
//   if (
//     error.response &&
//     error.response.status !== 404 &&
//     error.response.status !== 401 &&
//     error.response.status !== 406 &&
//     error.response.status !== 400
//   ) {
//     if (!error.response?.data?.message) {
//       alert("error", "An error occurred. Please try again later.");
//       isGenericErrorAlertShown = true;
//     }
//   }
// };

// Axios.interceptors.request.use((config) => {
//   isInternetAlertShown = false; // Reset the flag for each new request

//   const token = localStorage.getItem("user_token");

//   if (token) {
//     config.headers["Authorization"] = `${token}`;
//     config.headers["x-token"] = localStorage.getItem("user_token");
//   }

//   if (!window.navigator.onLine) {
//     showInternetAlert();
//     return Promise.reject(new axios.Cancel("No internet connection"));
//   }

//   return config;
// });

// Axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error instanceof axios.Cancel) {
//       showInternetAlert();
//       return Promise.reject(error);
//     }

//     if (error.response && error.response.status === 401) {
//       // if (
//       //   error?.response?.data?.error === "ACCESS_TOKEN_INAVLID" ||
//       //   error?.response?.data?.error === "ACCESS_TOKEN_EXPIRED"
//       // ) {

//       if (!isGenericErrorAlertShown) {
//         alert("error", "Session expired. Please log in again.");
//         isGenericErrorAlertShown = true;
//         logout();
//       }
//       // }
//     }

//     showGenericErrorAlert(error);

//     return Promise.reject(error);
//   }
// );

// export default Axios;

import axios from "axios";
import alert from "../utils/alert";
import { logout } from "../utils/logout";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BUILDFOLIO_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isInternetAlertShown = false;
let isGenericErrorAlertShown = false;

const showInternetAlert = () => {
  if (!isInternetAlertShown) {
    alert("error", "No internet connection. Please check your connection.");
    isInternetAlertShown = true;
  }
};

const showGenericErrorAlert = (error) => {
  if (
    error.response &&
    error.response.status !== 404 &&
    error.response.status !== 401 &&
    error.response.status !== 406 &&
    error.response.status !== 400
  ) {
    if (!error.response?.data?.message) {
      alert("error", "An error occurred. Please try again later.");
      isGenericErrorAlertShown = true;
    }
  }
};

Axios.interceptors.request.use((config) => {
  isInternetAlertShown = false; // Reset the flag for each new request

  const token = localStorage.getItem("user_token");
  const FCM_token=localStorage.getItem("FCM_TOKEN")
  

  if(FCM_token){
    config.headers["device-token"] = FCM_token
  }
  
  if (token) {
    config.headers["Authorization"] = `${token}`;
    config.headers["x-token"] = localStorage.getItem("user_token");
  }

  if (!window.navigator.onLine) {
    showInternetAlert();
    return Promise.reject(new axios.Cancel("No internet connection"));
  }

  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error instanceof axios.Cancel) {
      showInternetAlert();
      return;
    }

    if (error.response && error.response.status === 401) {
      if (!isGenericErrorAlertShown) {
        if (error.response.data.error) {
          alert("error", error.response.data.error);
          logout()
        } else {
          alert("error", "Session expired. Please log in again.");
          isGenericErrorAlertShown = true;
          logout();
        }
      }
      return;
    }

    showGenericErrorAlert(error);

    if (!isGenericErrorAlertShown) {
      return Promise.reject(error);
    }
  }
);

export default Axios;
