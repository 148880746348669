export function detectDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /mobile|android|iphone|ipad|iemobile|opera mini/i.test(userAgent);
  const isTablet = /ipad|android|tablet/i.test(userAgent);

  if (isMobile || isTablet) {
    return "Mobile";
  } else {
    return "Desktop";
  }
}




export function getDeviceType() {
  const userAgent = navigator.userAgent;
  if (/Android/i.test(userAgent)) {
    return "android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "ios";
  } else {
    return "web";
  }
}