import React, { useEffect, useState } from "react";
import Axios from "../../axios/axiosInstance";
import useDivLoader from "../../components/loaders/useDivLoader";

export const ContractorProfile = ({ onClose, userId, }) => {
  const { showLoader, hideLoader, Loader } = useDivLoader();
  const [details, setDetails] = useState();

  useEffect(() => {
    userId && getData();
  }, [userId]);

  const getData = () => {
    showLoader();
    Axios.get("license/" + userId)
      .then((res) => {
        setDetails(res.data.data);
     
      })
      .catch((err) => {
        err.response && alert("error", err.response.data.error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <>
      <div className="modal_header mb-4 ldr">
        <div className="d-flex align-items-center">
          <span className="me-2 pointer">
            <img onClick={onClose} src="img/arrow_back.svg" alt="arrow back" />
          </span>
          <span>Profile</span>
        </div>
      </div>
      {/* <div className="profile_img  text-center mb-5">
        <img src="img/profile.png" alt="profile" className="profile_iconV" />
      </div> */}
      <div className="form_output">
        <div className="input_grp">
          <div className="input_itm w-100">
            <label className="d-block">Business Name</label>
            <div className="out_txt">{details?.businessname}</div>
          </div>
        </div>
        <div className="input_grp mt-4">
          <div className="input_itm w-100">
            <label className="d-block">Contact Name</label>
            <div className="out_txt">{details?.primaryprincipalname}</div>
          </div>
        </div>
        {/* <div className="input_grp mt-4">
          <div className="input_itm w-100">
            <label className="d-block">Phone No.</label>
            <div className="out_txt">{details?.phonenumber}</div>
          </div>
        </div> */}
        <div className="input_grp mt-4">
          <div className="input_itm w-100">
            <label className="d-block">City</label>
            <div className="out_txt">{details?.city}</div>
          </div>
        </div>
        <div className="input_grp mt-4">
          <div className="input_itm w-100">
            <label className="d-block">State</label>
            <div className="out_txt">{details?.state}</div>
          </div>
        </div>
        <div className="input_grp mt-4">
          <div className="input_itm w-100">
            <label className="d-block">Zip</label>
            <div className="out_txt">{details?.zip}</div>
          </div>
        </div>
        <div className="input_grp mt-4">
          <div className="input_itm w-100">
            <label className="d-block">Lic. Type</label>
            <div className="out_txt">{details?.contractorlicensetypecodedesc}</div>
          </div>
        </div>
        <div className="input_grp mt-4">
          <div className="input_itm w-100">
            <label className="d-block">SP Code</label>
            <div className="out_txt">{details?.specialtycode1}</div>
          </div>
        </div>
        <div className="input_grp mt-4">
          <div className="input_itm w-100">
            <label className="d-block">SP Code Desc</label>
            <div className="out_txt">{details?.specialtycode1desc}</div>
          </div>
        </div>
      </div>
      <Loader type={"tableLines"} />
    </>
  );
};
