// import React, { useEffect, useState } from "react";
// import Axios from "../../axios/axiosInstance";
// import { formatDate } from "../../utils/dateConverter";
// import { useNavigate } from "react-router-dom";

// const NotificationsList = ({ onClose, is0pen }) => {
//     const [notificationsList, setNotificationList] = useState([])
//     const navigate = useNavigate()


//     useEffect(() => {
//         getNotificationList();
//     }, [is0pen]);

//     const getNotificationList = () => {
//         Axios.get("notifications").then((res) => {
//             console.log("notification",res.data.data)
//             setNotificationList(res.data.data);
//         }).catch((err) => {
//             err.response && alert("error", err.response.data.error);
//         })
//             .finally(() => {

//             });
//     };

//     return <>
//         <div className="modal_header mb-4 ldr">
//             <div className="d-flex align-items-center">
//                 <span onClick={() => {
//                     onClose()}} className="me-2 pointer">
//                     <img src="img/arrow_back.svg" alt="arrow back" />
//                 </span>
//                 <span>Notifications</span>
//             </div>
//         </div>

//         <div className="noti__content__body">
//             {notificationsList?.map((ele, index) => {
//                 return <div key={ele.id} onClick={() => {
//                     onClose()
//                     navigate(`/notification/${ele.id}/pId/${ele.permit_id}`)}} className="noti__content__itm">
//                     <div className="d-flex justify-content-between">
//                         <span className={`noti__content__txt ${ele?.seen === "no" && "active"}`}>{ele?.body}</span>
//                         <span className="noti__content__time ms-3">{formatDate(ele?.created_at, "date-time")}</span></div>
//                 </div>
//             })}

//         </div>



//     </>
// }

// export default NotificationsList



import React, { useEffect, useState } from "react";
import Axios from "../../axios/axiosInstance";
import { formatDate } from "../../utils/dateConverter";
import { useNavigate } from "react-router-dom";
import alert from "../../utils/alert";
import useDivLoader from "../../components/loaders/useDivLoader";
import { useAppContext } from "../../context/SharedData";

const NotificationsList = ({ onClose, is0pen }) => {
    const { showLoader, hideLoader, Loader } = useDivLoader();
    const [notificationsList, setNotificationList] = useState([]);
    const navigate = useNavigate();
    const {  setRefreshPage } = useAppContext();

    useEffect(() => {
        // Fetch notifications whenever `is0pen` changes
        setTimeout(() => {
            getNotificationList();
        }, 100);
    }, [is0pen]); // Dependency array ensures useEffect is triggered when `is0pen` changes

    const getNotificationList = async () => {
        showLoader()
        await Axios.get("notifications")
            .then((res) => {
         
                setNotificationList(res.data.data);
            })
            .catch((err) => {
                console.error("Failed to fetch notifications:", err);
                err.response && alert("error", err.response.data.error);
            }).finally(()=>{
                hideLoader()
            })
    };


    // const onClickMarkAllAsRead = async () => {
    //     await Axios.get("notifications")
    //         .then((res) => {
    //             alert("success", "Successfully marked all as read")
    //         })
    //         .catch((err) => {
    //             err.response && alert("error", err.response.data.error);
    //         }).finally(() => {
    //             getNotificationList()
    //         })
    // }

    return (
        <>
            <div className="modal_header mb-4 ldr">
                <Loader  type={"tableLines"}/>
                <div className="d-flex align-items-center">
                    <span onClick={onClose} className="me-2 pointer">
                        <img src="img/arrow_back.svg" alt="arrow back" />
                    </span>
                    <span>Notifications</span>
                </div>
            </div>

            <div className="noti__content__body">
                {notificationsList.length > 0 ? (
                    notificationsList.map((ele) => (
                        <div
                            key={ele.id}
                            onClick={() => {
                                onClose();
                                setRefreshPage(true)
                                navigate(`/notification/${ele.id}/pId/${ele.permit_id}`);
                            }}
                            className="noti__content__itm"
                        >
                            <div className="d-flex justify-content-between">
                                <span className={`noti__content__txt ${ele?.seen === "no" && "active"}`}>
                                    {ele?.body}
                                </span>
                                <span className="noti__content__time ms-3">
                                    {formatDate(ele?.created_at, "date-time")}
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No notifications available</p>
                )}
                {/* {notificationsList.length > 0 && <button onClick={onClickMarkAllAsRead} className="btn_cus mt30">Mark all as read</button>} */}
            </div>
        </>
    );
};

export default NotificationsList;
