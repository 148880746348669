import { type } from "@testing-library/user-event/dist/type";

export const CONTRACTOR_TYPE = [
  "Electrical Contractor",
  "Construction Contractor",
  // "HVAC",
  "Plumbing Contractor",
];


export const FILL_JOB_OPTIONS = ["ASAP", "Today", "Tomorrow", "This Week", "This Month"];

export const JOB_DAYS_OPTIONS = ["1 Day", "2-4 Days", "1 Week", "More than 1 week"];

export const LANGUAGES = [
  { label: "English", value: "English" },
  { label: "Spanish", value: "Spanish" },
  { label: "Russian", value: "Russian" },
  { label: "Chinese", value: "Chinese" },
  { label: "Vietnamese", value: "Vietnamese" },
];

// export const SUB_CATEGORIES = [
//   //Electrical Contractor
//   { label: "Residential", value: "02" ,type:"Electrical Contractor"},
//   { label: "Pump and irrigation", value: "03" ,type:"Electrical Contractor"},
//   { label: "Sign", value: "04" ,type:"Electrical Contractor"},
//   { label: "Limited energy", value: "06",type:"Electrical Contractor" },
//   { label: "Maintenance", value: "07",type:"Electrical Contractor" },
//   { label: "Domestic Pump", value: "3A" ,type:"Electrical Contractor"},
//   { label: "Lighting maintenance", value: "7A" ,type:"Electrical Contractor"},
//   { label: "Residential maintenance", value: "7B",type:"Electrical Contractor" },
//   // { label: "Restricted nonresidential maintenance", value: "7C",type:"Electrical Contractor" },
//   { label: "Appliance repair", value: "7D" ,type:"Electrical Contractor"},
//   { label: "Equipment repair", value: "7E" ,type:"Electrical Contractor"},
//   { label: "Doors and gate speciality", value: "10",type:"Electrical Contractor" },
//   { label: "HVAC/RFRG", value: "6A",type:"Electrical Contractor" },
//   { label: "HVAC/RFRG - restricted", value: "6B" ,type:"Electrical Contractor"},
//   //Plumbing Contractor
//   { label: "Journey level", value: "01" ,type:"Plumbing Contractor"},
//   { label: "Backflow specialty", value: "30" ,type:"Plumbing Contractor"},
//   { label: "Residential service", value: "04" ,type:"Plumbing Contractor"},
//   //HVAC
//   // { label: "Limited energy system", value: "06",type:"HVAC" },                                      
//  ];


export const SUB_CATEGORIES = [
  // Electrical Contractor
  { label: "Residential", value: "02", type: "Electrical Contractor" },
  { label: "Pump and irrigation", value: "03", type: "Electrical Contractor" },
  { label: "Sign", value: "04", type: "Electrical Contractor" },
  { label: "Limited energy", value: "06", type: "Electrical Contractor" },
  { label: "Maintenance", value: "07", type: "Electrical Contractor" },
  { label: "Domestic Pump", value: "3A", type: "Electrical Contractor" },
  { label: "Lighting maintenance", value: "7A", type: "Electrical Contractor" },
  { label: "Residential maintenance", value: "7B", type: "Electrical Contractor" },
  { label: "Appliance repair", value: "7D", type: "Electrical Contractor" },
  { label: "Equipment repair", value: "7E", type: "Electrical Contractor" },
  { label: "Doors and gate speciality", value: "10", type: "Electrical Contractor" },
  { label: "HVAC/RFRG", value: "6A", type: "Electrical Contractor" },
  { label: "HVAC/RFRG - restricted", value: "6B", type: "Electrical Contractor" },
  
  // Plumbing Contractor
  { label: "Journey level", value: "01", type: "Plumbing Contractor" },
  { label: "Backflow specialty", value: "30", type: "Plumbing Contractor" },
  { label: "Residential service", value: "04", type: "Plumbing Contractor" },

  // Construction Contractor
  { label: "Heating/Vent/Air-Conditioning and Refrig (HVAC/R)", value: "SM", type: "Construction Contractor" },
  { label: "GENERAL", value: "01", type: "Construction Contractor" },
  { label: "Insulation and Acoustical", value: "SO", type: "Construction Contractor" },
  { label: "Siding", value: "SW", type: "Construction Contractor" },
  { label: "Floor Covering and Counter Tops", value: "SK", type: "Construction Contractor" },
  { label: "PAINTING/WALLCOVERING", value: "CB", type: "Construction Contractor" },
  { label: "APPLIANCES/EQUIPMENT", value: "BG", type: "Construction Contractor" },
  { label: "Tile, Ceramic, Mosaic, Natural and MFG Stone", value: "RE", type: "Construction Contractor" },
  { label: "LANDSCAPING", value: "BW", type: "Construction Contractor" },
  { label: "Tree Removal Service", value: "RF", type: "Construction Contractor" },
  { label: "Cabinets, Millwork and Finish Carpentry", value: "SB", type: "Construction Contractor" },
  { label: "DRY WALL", value: "BK", type: "Construction Contractor" },
  { label: "MASONRY", value: "BZ", type: "Construction Contractor" },
  { label: "Excavation, Grading and Land Clearing", value: "SI", type: "Construction Contractor" },
  { label: "GUTTERS/DOWNSPOUTS", value: "CV", type: "Construction Contractor" },
  { label: "CONCRETE", value: "BI", type: "Construction Contractor" },
  { label: "PRESSURE WASHING", value: "PW", type: "Construction Contractor" },
  { label: "ROOFING", value: "CD", type: "Construction Contractor" },
  { label: "OVERHEAD/GARAGE DOORS", value: "OG", type: "Construction Contractor" },
  { label: "Signs", value: "SX", type: "Construction Contractor" },
  { label: "Swimming Pools, Spas and Hot Tubs", value: "RB", type: "Construction Contractor" },
  { label: "Suspended Ceiling and Acoustical Tile", value: "RA", type: "Construction Contractor" },
  { label: "Window Coverings", value: "RH", type: "Construction Contractor" },
  { label: "Framing and Rough Carpentry", value: "SL", type: "Construction Contractor" },
  { label: "Utilities and Telecommunications", value: "RG", type: "Construction Contractor" },
  { label: "Construction Clean-up", value: "SE", type: "Construction Contractor" },
  { label: "Awnings, Canopies, Patio Covers, Exterior Screens", value: "SA", type: "Construction Contractor" },
  { label: "Asbestos and Lead", value: "XX", type: "Construction Contractor" },
  { label: "GLAZING/GLASS", value: "BP", type: "Construction Contractor" },
  { label: "Paving/ Striping/ Seal Coating", value: "SS", type: "Construction Contractor" },
  { label: "FENCING", value: "BN", type: "Construction Contractor" },
  { label: "HANDYMAN", value: "HM", type: "Construction Contractor" },
  { label: "IRRIGATION/SPRINKLING SYSTEMS", value: "BV", type: "Construction Contractor" },
  { label: "FIRE PROTECT SYSTEM", value: "BO", type: "Construction Contractor" },
  { label: "Demolition and Salvage", value: "SF", type: "Construction Contractor" },
  { label: "Welding and Ornamental Metal", value: "RI", type: "Construction Contractor" },
  { label: "WATER CONDITIONING EQUIPMENT", value: "CP", type: "Construction Contractor" },
  { label: "Sanitation System / Side sewers", value: "SU", type: "Construction Contractor" },
  { label: "INST EQUIP/STAT FURN/LAB T/LO", value: "BT", type: "Construction Contractor" },
  { label: "BOILER/STEAM FIT/PROC PIPING", value: "AC", type: "Construction Contractor" },
  { label: "Manufactured/Mobile Home Set-up", value: "SR", type: "Construction Contractor" },
  { label: "COMMERCIAL/INDUSTRIAL/REFRIG", value: "AB", type: "Construction Contractor" },
  { label: "Locks, Security and Alarm Equipment", value: "SQ", type: "Construction Contractor" },
  { label: "Central Vacuum Systems", value: "SC", type: "Construction Contractor" },
  { label: "Steel Erectors", value: "SY", type: "Construction Contractor" },
  { label: "Sandblasting", value: "ST", type: "Construction Contractor" },
  { label: "Lathing and Plastering", value: "SP", type: "Construction Contractor" },
  { label: "Closets", value: "SD", type: "Construction Contractor" },
  { label: "Drilling, Blasting and Soil Sampling", value: "SH", type: "Construction Contractor" },
  { label: "METAL FABRICATION", value: "CG", type: "Construction Contractor" },
  { label: "Structural Pest Control", value: "SZ", type: "Construction Contractor" },
  { label: "Tanks and Tank Removal", value: "RC", type: "Construction Contractor" },
  { label: "Drain Cleaning / Snaking", value: "RK", type: "Construction Contractor" },
  { label: "HOUSE MOVING", value: "BR", type: "Construction Contractor" },
  { label: "Wood/Pellet and Gas Stoves", value: "RJ", type: "Construction Contractor" },
  { label: "Scaffolding and Safety Railings", value: "SV", type: "Construction Contractor" },
  { label: "WELL DRILLING", value: "WD", type: "Construction Contractor" },
  { label: "Industrial Equipment/Machines", value: "SN", type: "Construction Contractor" },
  { label: "ELEVATOR", value: "BL", type: "Construction Contractor" },
  { label: "Doors, Gates and Activating Devices", value: "SG", type: "Construction Contractor" },
  { label: "Fireproofing and Coating", value: "SJ", type: "Construction Contractor" },
  { label: "SERVICE STATION EQUIPMENT AND MAINTENANCE", value: "CF", type: "Construction Contractor" }
];
  

