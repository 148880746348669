import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage ,isSupported} from 'firebase/messaging';
import { firebaseConfig } from '../config/firebaseConfig';



const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async (handleMessage) => {


  try {
    // Request permission for notifications
    const isSupportedBrowser = await isSupported();
    const permission = await Notification.requestPermission();

    if (permission === 'granted' && isSupportedBrowser) {
  
      // Get the FCM token
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_BUILDFOLIO_VAPIDKEY
      });

      localStorage.setItem("FCM_TOKEN", token)
      // Optionally, send the token to your server
    } else {
      console.log('Notification permission denied.');
    }

    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log("messaging obj", { messaging })
      handleMessage(payload);
    });

  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

export { messaging, setupNotifications };
