
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import Select from "react-select";
import { JOB_POST_QUERY_PARAMS } from "../../../config/queryParams";
import alert from "../../../utils/alert";

const ProfileSwitch = ({ userData, setToggleScreen }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const description = queryParams.get("description") || "";

    const [loading, setLoading] = useState(false);

    const [selectedOption, setSelectedOption] = useState(null);




    const handleSubmit = (e) => {
        e.preventDefault()
        if(selectedOption?.data?.user_id){
        setLoading(true)
        // localStorage.setItem("sub_type", selectedOption.subscription_type);
        localStorage.setItem("user_id", selectedOption?.data?.user_id);
        localStorage.setItem("zip_code", selectedOption?.data?.zip);
        localStorage.setItem("l_id", selectedOption?.data?.license_id);          //prefill zip code in add post
        localStorage.setItem("l_name", selectedOption?.data?.contractorlicensetypecodedesc);
        userData?.customerId && localStorage.setItem("c_id", userData?.customerId);
        localStorage.setItem("user_token", userData?.token)


        setTimeout(() => {
            setLoading(false);
            setToggleScreen(false)
            if (!description) {
                navigate("/")
            } else {
                navigate(
                    `/post?${JOB_POST_QUERY_PARAMS(queryParams)}`
                );
            }
        }, 1200);
    }else{
        alert("error","Please select profile")
    }
    }


    return (
        <>
            <h6 className="boldtxt_on mt30  mb30 text-center">Which account do you want to access?</h6>
            {/* <p className="mb30 text-center">We’ll use this information to help you claim your Buildfolio profile.</p> */}


            <form onSubmit={handleSubmit}>
                <div className="min_hform acc__acs">
                    {/* <p className="ortxtAfBE me-2 mt-4" style={{ textAlign: "center" }}>OR</p> */}
                    <div className="input_grp">
                        <div className="d-flex align-items-start">
                            {/* <img src="img/business_icon.svg" alt="business_icon" className="icon_input me-2 mt-1" /> */}
                            <div className="input_itm w-100">
                                {/* <label htmlFor="businessName" className="d-block">Choose profile</label> */}
                                <Select
                                    className="inputFill border-0"
                                    value={selectedOption || ""}
                                    options={userData?.license?.map((ele) => { return { label: ele?.contractorlicensetypecode + "-" + ele?.contractorlicensenumber, value: ele.license_id, data: ele } })}
                                    onChange={(selectedOption) => {

                                        setSelectedOption(selectedOption);


                                    }}
                                    // defaultMenuIsOpen={true}
                                    // onInputChange={(inputValue) => { handleBusinessNameChange(inputValue) }}
                                    placeholder="Select..."
                                    isClearable
                                    isSearchable={false}
                                />
                                <span></span>
                            </div>
                        </div>
                    </div>
                    {/* {businessName === "" && <span className="text-danger">Please enter a valid business name</span>} */}
                </div>

                {/* <button type="submit" className="btn_cus">CONTINUE</button> */}
                {loading ? (
                    <Button
                        style={{ padding: "20px", height: "fit-content" }}
                        id="b"
                        // type="submit"
                        className="btn_cus"
                        loading={loading}
                    >
                        Continue
                    </Button>
                ) : (
                    <button id="b" type="submit" className="btn_cus">
                        Continue
                    </button>
                )}

                <p className="text-center mt-3">
                    Back to{" "}
                    <Link onClick={() => setToggleScreen(false)} className="bluedrk">Log in?</Link>
                </p>
            </form>


        </>
    );
};

export default ProfileSwitch;
