import React, { useState } from "react";
import { Placeholder } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { ThreeDots } from "react-loader-spinner";

const useDivLoader = () => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => {
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
  };

  const Loader = ({ type, color = "#C9D700", height = 50, width = 50 }) => {
    return isLoading ? (
      <>
        {type === "tableLines" ? (
          <div className="loader-overlay-dv">
            <div className="loader-container-dv">
              <ThreeDots
                visible={true}
                height="50"
                width="50"
                color="black"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </div>
        ) : (
          // <div className="mt-3">
          //   <Placeholder size="lg" as="p" animation="glow">
          //     <Placeholder style={{ height: "50px" }} xs={12} />
          //   </Placeholder>
          //   <Placeholder size="lg" as="p" animation="glow">
          //     <Placeholder style={{ height: "50px" }} size="lg" xs={10} />
          //   </Placeholder>
          //   <Placeholder size="lg" as="p" animation="glow">
          //     <Placeholder style={{ height: "50px" }} xs={11} />
          //   </Placeholder>
          //   <Placeholder size="lg" as="p" animation="glow">
          //     <Placeholder style={{ height: "25px" }} xs={8} />
          //   </Placeholder>
          //   <Placeholder size="lg" as="p" animation="glow">
          //     <Placeholder style={{ height: "25px" }} xs={12} />
          //   </Placeholder>
          //   <Placeholder size="lg" as="p" animation="glow">
          //     <Placeholder style={{ height: "25px" }} xs={12} />
          //   </Placeholder>
          // </div>
          <Placeholder as="p" animation="glow">
            <div className="gray-box ldr mt-30 mx_ht65">
              <div className="row">
                <div className="col-xxl-3 col-xl-4 col-md-6 mb-4">
                  <div className="door-view ">
                    <div className="door-img">
                      <Placeholder
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          aspectRatio: "1/1.2",
                        }}
                        xs={12}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 mb-4">
                  <Placeholder
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      aspectRatio: "1/1.2",
                    }}
                    xs={12}
                  />
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 mb-4">
                  <Placeholder
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      aspectRatio: "1/1.2",
                    }}
                    xs={12}
                  />
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6 mb-4">
                  <Placeholder
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      aspectRatio: "1/1.2",
                    }}
                    xs={12}
                  />
                </div>
              </div>
            </div>
          </Placeholder>
          //   <div className="loader-overlay-dv">
          //     <div className="loader-container-dv">
          //       <Spinner
          //         className="me-2"
          //         style={{ color: color, height: "20px", width: "20px" }}
          //         animation="grow"
          //       />{" "}
          //       <Spinner
          //         className="me-2"
          //         style={{ color: color, height: "20px", width: "20px" }}
          //         animation="grow"
          //       />{" "}
          //       <Spinner
          //         className="me-2"
          //         style={{ color: color, height: "20px", width: "20px" }}
          //         animation="grow"
          //       />
          //     </div>
          //   </div>
        )}
      </>
    ) : null;
  };

  return { showLoader, hideLoader, Loader, isLoading };
};

export default useDivLoader;
