import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import Axios from "../../axios/axiosInstance";
import alert from "../../utils/alert";
import { Button } from "antd";
import { JOB_POST_QUERY_PARAMS } from "../../config/queryParams";
import ProfileSwitch from "./signup/ProfileSwitch";
import { getDeviceType } from "../../utils/deviceDetection";
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { firebaseConfig } from "../../config/firebaseConfig";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const Login = () => {

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const description = queryParams.get("description") || "";

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [toggleScreen, setToggleScreen] = useState(false)
  const [userData, setUserData] = useState()

  let device_type = getDeviceType()

  useEffect(() => {
    const rememberMeChecked = localStorage.getItem("remember_me") === "true";
    if (rememberMeChecked) {
      const userEmail = localStorage.getItem("user_email") || "";
      // Set the email and remember me status when the component mounts
      setRememberMe(true);
      setValue("email", userEmail);
    }
  }, []);

  const checkPermission = async () => {
    if (Notification.permission === 'granted') {
      return true;
    } else if (Notification.permission === 'denied') {
      return false;
    } else {
      try {
        const permission = await Notification.requestPermission();
        return permission === 'granted';
      } catch (error) {
        return false;
      }
    }
  };

  const fetchToken = async () => {
    try {
      const permissionGranted = await checkPermission();

      if (permissionGranted) {
        let token = localStorage.getItem('FCM_TOKEN');

        if (!token) {
          token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_BUILDFOLIO_VAPIDKEY
          });

          if (token) {
            localStorage.setItem('FCM_TOKEN', token);
            console.log('FCM Token fetched:', token);
          } else {
            console.log('No FCM token available.');
          }
        } else {
          console.log('FCM Token already available:', token);
        }

        return token;
      } else {
        console.log('Notification permission denied.');
        return null;
      }
    } catch (error) {
      console.error('Error retrieving FCM token:', error);
      return null;
    }
  };


  // const onSubmit = async (data) => {
  //   const token = await fetchToken()
  //   setLoading(true)
  //   await Axios.post("login", {
  //     email: data.email,
  //     password: data.password,
  //     device_type: device_type,
  //     device_token: token || localStorage.getItem("FCM_TOKEN")
  //   })
  //     .then((res) => {
  //       if (res) {
  //         // localStorage.setItem("s_post",true)
  //         setUserData(res.data)
  //         localStorage.setItem("p-img", res.data.image)
  //         localStorage.setItem("sub_type", res.data.subscription_type);
  //         if (rememberMe) {
  //           localStorage.setItem("remember_me", true);
  //           localStorage.setItem("user_email", data.email);
  //         } else {
  //           localStorage.setItem("remember_me", false);
  //           localStorage.removeItem("remember_me");
  //           localStorage.removeItem("user_email");
  //         }
  //         alert("success", "Logged In successfull");
  //         if (res.data.license.length === 1) {
  //           localStorage.setItem("user_token", res.data.token);
  //           localStorage.setItem("user_id", res.data.license[0].user_id);
  //           localStorage.setItem("zip_code", res.data.license[0].zip);
  //           localStorage.setItem("l_id", res.data.license[0].license_id);          //prefill zip code in add post
  //           localStorage.setItem("l_name", res.data.license[0].contractorlicensetypecodedesc);
  //           res?.data?.customerId && localStorage.setItem("c_id", res.data.customerId);
  //           setTimeout(() => {
  //             setLoading(false);
  //             if (!description) {
  //               navigate("/")
  //             } else {
  //               navigate(
  //                 `/post?${JOB_POST_QUERY_PARAMS(queryParams)}`
  //               );
  //             }
  //           }, 1200);
  //         } else {
  //           setToggleScreen(true)
  //         }
  //       }
  //     })
  //     .catch((err) => err.response && alert("error", err?.response?.data?.error))
  //     .finally(() => {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //     });


  // };

  const onSubmit = async (data) => {
    setLoading(true); // Start loading indicator
  
    try {
      // Fetch the FCM token
      const token = await fetchToken();
  
      // Make the login request
      const response = await Axios.post("login", {
        email: data.email,
        password: data.password,
        device_type: device_type,
        device_token: token || localStorage.getItem("FCM_TOKEN")
      });
  
      if (response) {
        const resData = response.data;
        setUserData(resData);
  
        // Set local storage items
        localStorage.setItem("p-img", resData.image);
        localStorage.setItem("sub_type", resData.subscription_type);
  
        if (rememberMe) {
          localStorage.setItem("remember_me", true);
          localStorage.setItem("user_email", data.email);
        } else {
          localStorage.setItem("remember_me", false);
          localStorage.removeItem("user_email");
        }
  
        alert("success", "Logged In successfully");
  
        if (resData.license.length === 1) {
          localStorage.setItem("user_token", resData.token);
          localStorage.setItem("user_id", resData.license[0].user_id);
          localStorage.setItem("zip_code", resData.license[0].zip);
          localStorage.setItem("l_id", resData.license[0].license_id); // Prefill zip code in add post
          localStorage.setItem("l_name", resData.license[0].contractorlicensetypecodedesc);
          resData.customerId && localStorage.setItem("c_id", resData.customerId);
  
          // Navigate based on the existence of a description
          navigate(!description ? "/" : `/post?${JOB_POST_QUERY_PARAMS(queryParams)}`);
        } else {
          setToggleScreen(true);
        }
      }
    } catch (error) {
      // Handle errors here
      console.error('Login error:', error);
      alert("error", error.response?.data?.error || 'An error occurred');
    } finally {
      // Ensure loading indicator is hidden
      setLoading(false);
    }
  };
  

  return (
    <>
      {!toggleScreen ?
        <>
          <h3 className="text-center">Login</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="min_hform">
              <div className="input_grp">
                <div className="d-flex align-items-start">
                  <img src="img/email.svg" alt="email" className="icon_input me-2 mt-1" />
                  <div className="input_itm w-100">
                    <label htmlFor="email" className="d-block">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="inputFill border-0"
                      placeholder="Enter Email Address"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>

              {errors.email && <span className="text-danger">Please enter a valid email address</span>}
              <div className="input_grp mt30">
                <div className="d-flex align-items-start">
                  <img src="img/password.svg" alt="password" className="icon_input me-2 mt-1" />
                  <div className="input_itm w-100">
                    <label htmlFor="password" className="d-block">
                      Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={view ? "" : "password"}
                        id="password"
                        className="inputFill border-0"
                        placeholder="Enter Password"
                        {...register("password", {
                          required: "Password is required",
                          validate: (value) =>
                            value.trim().length >= 8 || "Password must be at least 8 characters long",
                        })}
                      />
                      {view ? (
                        <img
                          onClick={() => setView(false)}
                          src="img/eye.svg"
                          alt="eye"
                          className="position-absolute  pwd_icon"
                        />
                      ) : (
                        <img
                          onClick={() => setView(true)}
                          src="img/eyeClose.svg"
                          alt="eye"
                          className="position-absolute  pwd_icon"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {errors.password && <span className="text-danger">Please enter your password</span>}

              <div className="RemMe d-flex align-items-center mt-2">
                <input
                  type="checkbox"
                  id="RemMe"
                  className="pointer"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />

                <label htmlFor="RemMe" className="ms-2 pointer">
                  {" "}
                  Remember me
                </label>
              </div>
            </div>

            {loading ? (
              <Button
                style={{ padding: "20px", height: "fit-content" }}
                id="b"
                type="submit"
                className="btn_cus"
                loading={loading}
              >
                Login
              </Button>
            ) : (
              <button disabled={loading} id="b" type="submit" className="btn_cus">
                Login
              </button>
            )}

            <p className="text-center mt-3">
              Don’t have an account?{" "}
              <Link
                state={location?.state}
                to={`/welcome?${JOB_POST_QUERY_PARAMS(queryParams)}`}
                className="bluedrk me-1"
              >
                Sign up
              </Link>
            </p>
          </form>
        </> :
        <ProfileSwitch userData={userData} setToggleScreen={setToggleScreen} />
      }
    </>
  );
};

export default Login;
