// frontend/src/SavedCards.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Axios from '../../axios/axiosInstance';
import CustomModal from '../../components/common/CustomModal';
import alert from '../../utils/alert';
import useLoader from '../../components/loaders/useLoader';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/SharedData';
import { error } from 'jquery';

const SavedCards = ({ priceId, price }) => {
    const { setRefreshPage, refreshPage, } = useAppContext()
    const navigate = useNavigate()
    const customerId = localStorage.getItem("c_id") || null;
    const { showLoader, hideLoader, Loader, isLoading } = useLoader();
    const [cards, setCards] = useState([]);
    const [show, setShow] = useState(false)
    const [cardId, setCardId] = useState("")
    const [modalType, setModalType] = useState("")

    const reset = () => {
        setShow(false)
        setModalType("")
    }

    const fetchSavedCards = async () => {
        try {
            const response = await Axios.get(`me/cards`);
            setCards(response.data.data);
        } catch (error) {
            console.error('Error fetching saved cards:', error);
        }
    };
    useEffect(() => {
        fetchSavedCards();
    }, [customerId]);

    // const handleSubmit = async (id) => {
    //     showLoader()
    //     await Axios.post(`plans/subscribe`, {
    //         priceId: priceId || null,
    //         payment_id: cardId,
    //     }).then((res) => {
    //         console.log("statis",res)
    //         setRefreshPage(true)
    //         alert("success", "Payment successfull")
    //         reset()
    //         navigate("/plans")
    //     }).catch((err) => {
    //         alert("error", err.response.data.message)
    //     }).finally(() => {
    //         hideLoader()
    //     })
    // };
    const handleSubmit = async (id) => {
        try {
            // Show loader before making the request
            showLoader();

            // Make the API request
            const response = await Axios.post('subscription/buy', {
                priceId: priceId || null,
                payment_id: cardId,
            });

            // Handle successful response
       
            setRefreshPage(true);
            alert("success", "Payment successful");
            reset();
            navigate("/plans");
        } catch (err) {
            // Handle error response
            if (err.response && err.response.data && error?.response?.data?.error) {
                alert("error", error?.response?.data?.error);
            } else {
                alert("error", "Payment failed! Please try again.");
            }
        } finally {
            // Hide loader after the request is done
            hideLoader();
        }
    };

    const handleDelete = async (id) => {
        showLoader();
        await Axios.delete(`me/cards/${cardId}`).then((res) => {
            alert("success", "deleted ")
            fetchSavedCards()
            reset()
        }).catch((err) => {
            alert("error", error?.response?.data?.error);
        }).finally(() => {
            hideLoader()
        })
    };


    return (
        <>
            <Loader />
            <div>
                {cards.length > 0 ? (

                    cards.map((card) => (
                        <div onClick={() => {
                            setModalType("paymentConfirm")
                            setCardId(card.id)
                            setShow(true)
                        }} className="input_grp mt-3">
                            <div className="bg__light__grey p-3 d-flex align-items-center justify-content-between">
                                <div className="savecard d-flex align-items-center">
                                    <img src="img/Cards--icon--circle.svg" alt="Cards--icon--circle" className='me-3' />
                                    <div className="d-inline"><label className='d-block'>{card.card.brand} Card </label><span className='d-block fz14 graytxt'>**** **** **** {card.card.last4}</span></div>
                                </div>
                                <div className="dlt pointer">
                                    <img onClick={(e) => {
                                        setCardId(card.id)
                                        e.stopPropagation()
                                        e.preventDefault()
                                        setModalType("delete")
                                        setShow(true)
                                    }} src="img/delete--orange.svg" alt="delete_icon" />
                                </div>
                            </div>
                        </div>
                    ))

                ) : (
                    <p>No saved cards</p>
                )}
            </div>
            <CustomModal modalBodyText={modalType === "delete" ? "Are you sure you want to Delete this card?" : price} type={modalType} show={show} handleChange={modalType === "paymentConfirm" ? handleSubmit : handleDelete} onHide={() => setShow(false)} handleClose={() => setShow(false)} />
        </>
    );
};

export default SavedCards;
