import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
const DashboardLayout = () => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Adjust the breakpoint as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check for mobile view on component mount
    handleResize();

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="dashboard">
        <div className="d-flex">
          <aside>
            <Sidebar />
          </aside>
          <div className="dashboard_wrp">
            <Header />
            <div className={location.pathname !== "/map" ? "dash_content" : "dash_map"}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardLayout;
