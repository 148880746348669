import Axios from "../axios/axiosInstance";

/**
 * Fetches a list of licenses based on search parameters.
 * @param {string} search - The search query for filtering licenses.
 * @param {number} page - The page number for pagination.
 * @param {number} [limit] - The maximum number of licenses per page. Optional.
 * @returns {Promise} A Promise that resolves with the response data or rejects with an error.
 */


const getLicenseList = async (sharedData, page, limit, showVerifiedList) => {
  try {
    let url = `license`;

    // Append search query parameter if it exists
    if (sharedData.search) {
      url += `?search=${sharedData?.search ? sharedData?.search.replace(/#/g, '') : ''}`;
    }

    // Append city query parameter if it exists
    if (sharedData.city) {
      url += `${url.includes("?") ? "&" : "?"}city=${sharedData.city}`;
    }

    // Append zip query parameter if it exists
    if (sharedData.zip) {
      url += `${url.includes("?") ? "&" : "?"}zip=${sharedData.zip}`;
    }

    // Append contractortype query parameter if it exists
    if (sharedData.type) {
      const contractorType =
        sharedData.type.toUpperCase() === "ALL" ? "" : sharedData.type.toUpperCase();
      url += `${url.includes("?") ? "&" : "?"}contractortype=${contractorType}`;
    }

    // Append page query parameter
    url += `${url.includes("?") ? "&" : "?"}page=${page}`;

    // Append userId query parameter if it exists
    const userId = localStorage.getItem("user_id");
    if (userId) {
      url += `${url.includes("?") ? "&" : "?"}userId=${userId}`;
    }

    // Append limit query parameter if it exists
    if (limit) {
      url += `&limit=${limit}`;
    }

    // Append postId query parameter if it exists
    if (sharedData.postId) {
      url += `&postId=${sharedData.postId}`;
    }
    if (showVerifiedList) {
      url += `&status=${showVerifiedList}`;
    }
    if (localStorage.getItem("l_id")) {
      url += `&profile_id=${localStorage.getItem("l_id")}`
    }
    // Send a GET request to fetch licenses with search, page, and optional limit parameters
    const response = await Axios.get(url);

    // Return the response data
    return response?.data;
  } catch (error) {
    // Handle any errors that occur during the request
    error.response && console.error("Error fetching license list:", error);
  }
};

export default getLicenseList;
