import "./App.scss";
import React, { Suspense, lazy, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { createHashRouter, RouterProvider } from "react-router-dom";
// import Dashboard from "./pages/dashboard/Dashboard";
import DashboardLayout from "./components/layout/DashboardLayout";
import OnboardingLayout from "./components/layout/OnboardingLayout";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/signup/Signup";
// import DashboardList from "./pages/dashboard/DashboardList";
import Chat from "./pages/chat/Chat";
// import Post from "./pages/post/Post";
import Favourite from "./pages/favourite/Favourite";
import Menu from "./pages/menu/Menu";
import { AppProvider, useAppContext } from "./context/SharedData";
import FullScreenLoader from "./components/loaders/FullScreenLoader";
import WelcomeFirst from "./pages/auth/signup/WelcomeFirst";
import { UserProfile } from "./pages/profile/UserProfile";
import VerifyPhone from "./pages/auth/signup/VerifyPhone";
import WebsiteLayout from "./components/layout/WebsiteLayout";
import UserWebsite from "./pages/website/UserWebsite";
import StripePayment from "./pages/paymentGateway/StripePayment";
import ChoosePLan from "./pages/paymentGateway/ChoosePlan";

import DomainSetup from "./pages/website/DomainSetup";
import PlanAndPayment from "./pages/paymentGateway/PlanAndPayment";
import ProfileList from "./pages/auth/signup/ProfileList";

import { setupNotifications } from "./firebase/firebase"
import { toastNotification, sendNativeNotification } from './firebase/notificationHelpers';
import useVisibilityChange from './useVisibilityChange';
import { register } from './firebase/serviceWorker';

import { ToastContainer } from 'react-toastify';
import NotificationDetails from "./pages/notifications/NotificationDetails";

const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const DashboardList = lazy(() => import("./pages/dashboard/DashboardList"));
const Post = lazy(() => import("./pages/post/Post"));

function App() {
  const [refreshUserProfileApi,setRefreshUserPorofileApi]=useState(false)

  const isForeground = useVisibilityChange();
 
  const handleClick = (type) => {
    const baseUrl = "https://buildfolio.co"; // Base URL for native redirection

    switch (type) {
      case "toast":
        // Handle toast notification redirection
        window.location.hash = "#?notify=true";
        break;

      case "native":
        // Redirect to the base URL
        window.location.href = baseUrl;
        break;

      default:
        console.warn("Unknown type:", type);
        break;
    }
  };

  useEffect(() => {
    setupNotifications((payload) => {
  
      if (isForeground) {
    
        setRefreshUserPorofileApi(true)
        // Handle foreground notification
        toastNotification({
          handleClick: handleClick,
          title: payload.data.title,
          description: payload.data.body,
          status: "info",
        });
      } else {
     
        // Handle background notification
        // sendNativeNotification({
        //   handleClick: handleClick,
        //   title: payload.notification.title,
        //   body: payload.notification.body,
        // });
      }
    });
  }, [isForeground]);

  const router = createHashRouter([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/",
          element: <DashboardList />,
        },
        {
          path: "/map",
          element: <Dashboard />,
        },
        {
          path: "/chat",
          element: <Chat />,
        },
        {
          path: "/post",
          element: <Post />,
        },
        {
          path: "/menu",
          element: <Menu />,
        },
        {
          path: "/favourite",
          element: <Favourite />,
        },
        {
          path: 'favourite/:groupId',
          element: <Favourite />,
        },
        {
          path: "/user-profile",
          element: <UserProfile />
        },
        {
          path: "/stripe",
          element: <StripePayment />
        },
        {
          path: "/choose-plan",
          element: <ChoosePLan />
        },
        {
          path: "/plans",
          element: <PlanAndPayment />
        },
        {
          path: "/stripe-pay",
          element: <StripePayment />
        },
        {
          path: "/domain-setup",
          element: <DomainSetup />
        },
        {
          path: "/notification/:id/pid/:pId",
          element: <NotificationDetails />
        },
      ],
    },
    {
      path: "/",
      element: <OnboardingLayout />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/welcome",
          element: <WelcomeFirst />,
        },
        {
          path: "/signup",
          element: <Signup />,
        },
        {
          path: "/verify-phone",
          element: <VerifyPhone />
        },
        {
          path: "/profiles",
          element: <ProfileList />
        },
      ],
    },
    {
      path: "/",
      element: <WebsiteLayout />,
      children: [{
        path: "/user-portal",
        element: <UserWebsite />
      }]
    }
  ]);
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <AppProvider setRefreshUserPorofileApi={setRefreshUserPorofileApi} refreshUserProfileApi={refreshUserProfileApi}>
        <RouterProvider router={router} />
        <ToastContainer />
      </AppProvider>
    </Suspense>
  );
}

export default App;
