import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import Axios from '../../axios/axiosInstance';
import useLoader from '../../components/loaders/useLoader';


const ChoosePlan = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [platinumDuration, setPlatinumDuration] = useState('month');
  const [diamondDuration, setDiamondDuration] = useState('month');
  const { showLoader, hideLoader, Loader, isLoading } = useLoader();
  const isUpgradedPlan = location?.state?.isUpgradePlan || null

  useEffect(() => {
    getPlans()
  }, [])

  const handlePlatinumToggle = (duration) => {
    setPlatinumDuration(duration);
  };

  const handleDiamondToggle = (duration) => {
    setDiamondDuration(duration);
  };

  const [platinumProducts, setPlatinumProducts] = useState([]);
  const [diamondProducts, setDiamondProducts] = useState([]);

  const getPlans = async () => {
    showLoader();
    await Axios.get("subscription/plans")
      .then((res) => {
        const products = res.data.data;
        const platinumProducts = products.filter(product => product.name.toLowerCase().includes("platinum"));
        const diamondProducts = products.filter(product => product.name.toLowerCase().includes("diamond"));
        setPlatinumProducts(platinumProducts);
        setDiamondProducts(diamondProducts);
      })
      .catch((err) => {
        err.response && alert("error", err.response.data.error);
      })
      .finally(() => {
        hideLoader();
      });
  };




  return (
    <>
      <Loader />
      <div>
        <div className="heading mb-3">
          <img onClick={() => navigate(-1)} src="img/backicon.svg" alt="back icon" className='me-2 pointer' />  Select a plan you would like to have
        </div>
        <div className="row">
          <div className="col-xl-8">
            <div className="row ">
              {platinumProducts?.map((ele) => {
                if (platinumDuration === "month" && ele.name === "BuildFolio platinum monthly"
                ) {
                  return <>{!isUpgradedPlan &&
                    <div className="col-md-6 ldr">
                      <div className="chooseplan px-3 py-4 border--gray border--rad10 h-100 d-flex justify-content-between flex-column">
                        <div className="d-block">
                          <div className="chooseplan__header d-flex align-items-center justify-content-between">
                            <div className="col-left d-flex align-items-center">
                              <img src="img/platinum.svg" alt="plan icon" className='plan__icon me-1' />
                              <div className="plan__txt">Platinum</div>
                            </div>
                            <div className="col-right">
                              <div className="chooseplan--toggle d-flex align-items-center">
                                <button
                                  className={`toggle__text border-0 ${platinumDuration === 'month' ? 'active' : ''}`}
                                  onClick={() => handlePlatinumToggle('month')}
                                >
                                  Month
                                </button>
                                <button
                                  className={`toggle__text border-0 ${platinumDuration === 'yearly' ? 'active' : ''}`}
                                  onClick={() => handlePlatinumToggle('yearly')}
                                >
                                  Yearly
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="center_line--dash my-3" />
                          <div className="chooseplan--details">
                            <ul className="details--group ps-0">
                              <li className='details--list d-flex'>
                                <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                                Can post unlimited jobs.
                              </li>
                              <li className='details--list d-flex'>
                                <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                                In case any new permit will be issued in your locality, you will get notified.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="chooseplan__footer d-flex align-items-center justify-content-between">
                          <div className="chooseplan__footer__price">
                            {platinumDuration === 'month' ? `$${ele.price}` : 'N/A'}<span>/ {platinumDuration === 'month' ? 'Mo' : 'Year'}</span>
                          </div>
                          <button onClick={() => navigate("/stripe-pay", { state: { priceKey: ele.default_price,price:ele?.price } })} className="btn_cus wfc py-2">Choose a plan</button>
                        </div>
                      </div>
                    </div>
                  }
                  </>
                } else if (platinumDuration === "yearly" && ele.name === "BuildFolio platinum Yearly"
                ) {
                  return <div className="col-md-6">
                    <div className="chooseplan px-3 py-4 border--gray border--rad10 h-100 d-flex justify-content-between flex-column">
                      <div className="d-block">
                        <div className="chooseplan__header d-flex align-items-center justify-content-between">
                          <div className="col-left d-flex align-items-center">
                            <img src="img/platinum.svg" alt="plan icon" className='plan__icon me-1' />
                            <div className="plan__txt">Platinum</div>
                          </div>
                          <div className="col-right">
                            <div className="chooseplan--toggle d-flex align-items-center">
                              <button
                                className={`toggle__text border-0 ${platinumDuration === 'month' ? 'active' : ''}`}
                                onClick={() => handlePlatinumToggle('month')}
                              >
                                Month
                              </button>
                              <button
                                className={`toggle__text border-0 ${platinumDuration === 'yearly' ? 'active' : ''}`}
                                onClick={() => handlePlatinumToggle('yearly')}
                              >
                                Yearly
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="center_line--dash my-3" />
                        <div className="chooseplan--details">
                          <ul className="details--group ps-0">
                            <li className='details--list d-flex'>
                              <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                              Can post unlimited jobs.
                            </li>
                            <li className='details--list d-flex'>
                              <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                              In case any new permit will be issued in your locality, you will get notified.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="chooseplan__footer d-flex align-items-center justify-content-between">
                        <div className="chooseplan__footer__price">
                          {platinumDuration === 'yearly' ? `$${ele.price}` : 'N/A'}<span>/ {platinumDuration === 'month' ? 'Mo' : 'Year'}</span>
                        </div>
                        <button onClick={() => navigate("/stripe-pay", { state: { priceKey: ele.default_price,price:ele?.price } })} className="btn_cus wfc py-2">Choose a plan</button>
                      </div>
                    </div>
                  </div>
                }
              })}

              {diamondProducts?.map((ele) => {
                if (diamondDuration === "month" && ele.name === "Buildfolio Diamond Monthly") {
                  return <div className="col-md-6 mt-4 mt-md-0">
                    <div className="chooseplan px-3 py-4 border--gray border--rad10 bg__light__grey h-100 d-flex  flex-column justify-content-between">
                      <div className="d-block">
                        <div className="chooseplan__header d-flex align-items-center justify-content-between">
                          <div className="col-left d-flex align-items-center">
                            <img src="img/diamond.svg" alt="plan icon" className='plan__icon me-1' />
                            <div className="plan__txt">Diamond</div>
                          </div>
                          <div className="col-right">
                            <div className="chooseplan--toggle d-flex align-items-center">
                              <button
                                className={`toggle__text border-0 ${diamondDuration === 'month' ? 'active' : ''}`}
                                onClick={() => handleDiamondToggle('month')}
                              >
                                Month
                              </button>
                              <button
                                className={`toggle__text border-0 ${diamondDuration === 'yearly' ? 'active' : ''}`}
                                onClick={() => handleDiamondToggle('yearly')}
                              >
                                Yearly
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="center_line--dash my-3" />
                        <div className="chooseplan--details">
                          <ul className="details--group ps-0">
                            <li className='details--list d-flex'>
                              <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                              Can post unlimited jobs.
                            </li>
                            <li className='details--list d-flex'>
                              <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                              In case any new permit will be issued in your locality, you will get notified.
                            </li>
                            <li className='details--list d-flex'>
                              <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                              Get optimized website designed and hosted.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="chooseplan__footer d-flex align-items-center justify-content-between">
                        <div className="chooseplan__footer__price">
                          {diamondDuration === 'month' ? `$${ele.price}` : 'N/A'}<span>/ {diamondDuration === 'month' ? 'Mo' : 'Year'}</span>
                        </div>
                        <button onClick={() => navigate("/stripe-pay", { state: { priceKey: ele.default_price,price:ele?.price } })} className="btn_cus wfc py-2">Choose a plan</button>
                      </div>
                    </div>
                  </div>
                } else if (diamondDuration === "yearly" && ele.name === "BuildFolio Diamond Yearly") {
                  return <div className="col-md-6 mt-4 mt-md-0">
                    <div className="chooseplan px-3 py-4 border--gray border--rad10 bg__light__grey h-100 d-flex  flex-column justify-content-between">
                      <div className="d-block">
                        <div className="chooseplan__header d-flex align-items-center justify-content-between">
                          <div className="col-left d-flex align-items-center">
                            <img src="img/diamond.svg" alt="plan icon" className='plan__icon me-1' />
                            <div className="plan__txt">Diamond</div>
                          </div>
                          <div className="col-right">
                            <div className="chooseplan--toggle d-flex align-items-center">
                              <button
                                className={`toggle__text border-0 ${diamondDuration === 'month' ? 'active' : ''}`}
                                onClick={() => handleDiamondToggle('month')}
                              >
                                Month
                              </button>
                              <button
                                className={`toggle__text border-0 ${diamondDuration === 'yearly' ? 'active' : ''}`}
                                onClick={() => handleDiamondToggle('yearly')}
                              >
                                Yearly
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="center_line--dash my-3" />
                        <div className="chooseplan--details">
                          <ul className="details--group ps-0">
                            <li className='details--list d-flex'>
                              <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                              Can post unlimited jobs.
                            </li>
                            <li className='details--list d-flex'>
                              <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                              In case any new permit will be issued in your locality, you will get notified.
                            </li>
                            <li className='details--list d-flex'>
                              <img src="img/check_fill-small-teal.svg" alt="check icon" className='checkIcon' />
                              Get optimized website designed and hosted.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="chooseplan__footer d-flex align-items-center justify-content-between">
                        <div className="chooseplan__footer__price">
                          {diamondDuration === 'yearly' ? `$${ele.price}` : 'N/A'}<span>/ {diamondDuration === 'month' ? 'Mo' : 'Year'}</span>
                        </div>
                        <button onClick={() => navigate("/stripe-pay", { state: { priceKey: ele.default_price,price:ele?.price } })} className="btn_cus wfc py-2">Choose a plan</button>
                      </div>
                    </div>
                  </div>
                }
              })}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChoosePlan;



