// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import alert from "../../utils/alert";

// const DomainSetup = () => {
//     const navigate = useNavigate()
//     const [isSubDomain, setIsSubDomain] = useState(false); // Initialize state with false
//     const [name, setName] = useState("")
//     const [error, setError] = useState("")
//     const [website, setWebsite] = useState("")
//     const [websiteError, setWebsiteError] = useState("")

//     const handleDomainChange = (event) => {
//         setError("")
//         setWebsiteError("")
//         setIsSubDomain(event.target.value === "sub_domain");
//     };

//     const handleWebsiteChange = (e) => {
//         const url = e.target.value.trim();
//         setWebsite(url);

//         // Basic URL validation
//         if (url.length > 0 && !isValidUrl(url)) {
//             setWebsiteError("Please enter a valid website URL");
//         } else {
//             setWebsiteError("");
//         }
//     };

//     const isValidUrl = (url) => {
//         // Adjusted URL validation regex
//         const urlRegex = /^(?!www\.|https:\/\/)([a-zA-Z0-9-]+\.)?name\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/;
//        
//         return urlRegex.test(url);
//     };


//     const handleOkClick = () => {
//         if (isSubDomain && name.trim().length < 1) {
//             setError("Please enter subdomain name");
//             return;
//         }
//         if (!isSubDomain && website.trim().length < 1) {
//             setWebsiteError("Please enter website URL");
//             return;
//         }
//         if (!isSubDomain && websiteError) {
//             setWebsiteError("Please enter a valid website URL");
//             return;
//         }

//         navigate("/choose-plan");
//     };


//     return (
//         <>
//             <div className="row mt-4">
//                 <div className="col-lg-4 col-md-6">
//                     <div className="d-flex align-items-start">
//                         <img onClick={() => navigate(-1)} src="img/backicon.svg" alt="back icon" className='me-2 mt-1 pointer' />
//                         <p className="text-dark fz16"> To access the <b>“Need a website feature”,</b> you'll need to subscribe to our premium plan.</p>
//                     </div>
//                     <div className="bg__light__gray py-4 px-3 border--rad10">
//                         <p className="text-dark fz16"> Add these details to your domain.</p>
//                         <div className="d-flex mb-3">
//                             <input
//                                 checked={!isSubDomain}
//                                 type="radio"
//                                 id="my_domain"
//                                 name="domain"
//                                 className="me-2"
//                                 value="my_domain" // Corrected value
//                                 onChange={handleDomainChange}
//                             />
//                             <label htmlFor="my_domain" className="pointer fz16">I have my own domain.</label>
//                         </div>
//                         {!isSubDomain && (
//                             <>
//                                 <div className="border--gray--secondry border--rad10 p-3 my-sm-4 my-3 bg-white">
//                                     <div className="row">
//                                         <div className="col-6">
//                                             <label>DNS Type</label>
//                                             <div className="output__data--txt">AN</div>
//                                         </div>
//                                         <div className="col-6">
//                                             <label>Domain Name</label>
//                                             <div className="output__data--txt">James Potter</div>
//                                         </div>
//                                         <div className="center_line--dash my-3" />
//                                         <div className="col-6">
//                                             <label>IP Address</label>
//                                             <div className="output__data--txt">10.204.125.24</div>
//                                         </div>
//                                         <div className="col-6">
//                                             <label>TTL</label>
//                                             <div className="output__data--txt">3600 (1 hr)</div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="input_grp mb-4">
//                                     <div className="d-flex align-items-start">
//                                         <img src="img/webIcon.svg" alt="phone" className="icon_input me-2 mt-1" />
//                                         <div className="input_itm w-100">
//                                             <label htmlFor="phone" className="d-block">
//                                                 Website
//                                             </label>
//                                             <input
//                                                 className="inputFill border-0"
//                                                 placeholder="https://www.yourwebsite.com"
//                                                 type="url"
//                                                 onChange={handleWebsiteChange}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <p style={{ color: "red" }} className="error">
//                                     {websiteError}
//                                 </p>
//                             </>

//                         )}

//                         <div className="d-flex ">
//                             <input
//                                 checked={isSubDomain}
//                                 type="radio"
//                                 id="sub_domain"
//                                 name="domain"
//                                 className="me-2"
//                                 value="sub_domain"
//                                 onChange={handleDomainChange}
//                             />
//                             <label htmlFor="sub_domain" className="pointer fz16">I will use buildfolio sub domain.</label>
//                         </div>
//                         {isSubDomain &&
//                             <>
//                                 <p className="fz16 text-dark mt-4">Give your subdomain a name.</p>
//                                 <div className="d-flex align-items-center">
//                                     <input onChange={(e) => {
//                                         if (e?.target?.value.trim().length > 0) {
//                                             setError("")
//                                         }
//                                         setName(e.target.value)
//                                     }} type="text" className="form-control border--rad10 max--w--184 me-3" />
//                                     <span className="fz18 DMSans_med">.buildfolio.co</span>
//                                 </div>
//                                 <p style={{ color: "red" }} className="error">
//                                     {error}
//                                 </p>
//                             </>}

//                     </div>
//                     <div onClick={() => {handleOkClick()}} className="btn_cus wfc py-2 px-5 mt-4">OK</div>

//                 </div>

//             </div>



//         </>
//     );
// };

// export default DomainSetup;


// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

// const DomainSetup = () => {
//     const navigate = useNavigate();
//     const [isSubDomain, setIsSubDomain] = useState(false);
//     const [name, setName] = useState("");
//     const [error, setError] = useState("");
//     const [website, setWebsite] = useState("");
//     const [websiteError, setWebsiteError] = useState("");

//     const handleDomainChange = (event) => {
//         setError("");
//         setWebsiteError("");
//         setIsSubDomain(event.target.value === "sub_domain");
//     };

//     const handleWebsiteChange = (e) => {
//         const url = e.target.value.trim();
//         setWebsite(url);

//         // Basic URL validation
//         if (url.length > 0 && !isValidUrl(url)) {
//             setWebsiteError("Please enter a valid website URL");
//         } else {
//             setWebsiteError("");
//         }
//     };

//     const isValidUrl = (url) => {
//         // Adjusted URL validation regex
//         const urlRegex = /^(?!www\.|https:\/\/)([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/;
//         return urlRegex.test(url);
//     };

//     const handleOkClick = () => {
//         if (isSubDomain && name.trim().length < 1) {
//             setError("Please enter subdomain name");
//             return;
//         }
//         if (!isSubDomain && website.trim().length < 1) {
//             setWebsiteError("Please enter website URL");
//             return;
//         }
//         if (!isSubDomain && websiteError) {
//             setWebsiteError("Please enter a valid website URL");
//             return;
//         }

//         navigate("/choose-plan");
//     };

//     return (
//         <>
//             <div className="row mt-4">
//                 <div className="col-lg-4 col-md-6">
//                     <div className="d-flex align-items-start">
//                         <img onClick={() => navigate(-1)} src="img/backicon.svg" alt="back icon" className='me-2 mt-1 pointer' />
//                         <p className="text-dark fz16"> To access the <b>“Need a website feature”,</b> you'll need to subscribe to our premium plan.</p>
//                     </div>
//                     <div className="bg__light__gray py-4 px-3 border--rad10">
//                         <p className="text-dark fz16"> Add these details to your domain.</p>
//                         <div className="d-flex mb-3">
//                             <input
//                                 checked={!isSubDomain}
//                                 type="radio"
//                                 id="my_domain"
//                                 name="domain"
//                                 className="me-2"
//                                 value="my_domain"
//                                 onChange={handleDomainChange}
//                             />
//                             <label htmlFor="my_domain" className="pointer fz16">I have my own domain.</label>
//                         </div>
//                         {!isSubDomain && (
//                             <>
//                                 <div className="border--gray--secondry border--rad10 p-3 my-sm-4 my-3 bg-white">
//                                     <div className="row">
//                                         <div className="col-6">
//                                             <label>DNS Type</label>
//                                             <div className="output__data--txt">AN</div>
//                                         </div>
//                                         <div className="col-6">
//                                             <label>Domain Name</label>
//                                             <div className="output__data--txt">James Potter</div>
//                                         </div>
//                                         <div className="center_line--dash my-3" />
//                                         <div className="col-6">
//                                             <label>IP Address</label>
//                                             <div className="output__data--txt">10.204.125.24</div>
//                                         </div>
//                                         <div className="col-6">
//                                             <label>TTL</label>
//                                             <div className="output__data--txt">3600 (1 hr)</div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="input_grp mb-4">
//                                     <div className="d-flex align-items-start">
//                                         <img src="img/webIcon.svg" alt="website icon" className="icon_input me-2 mt-1" />
//                                         <div className="input_itm w-100">
//                                             <label htmlFor="website" className="d-block">
//                                                 Website
//                                             </label>
//                                             <input
//                                                 id="website"
//                                                 className="inputFill border-0"
//                                                 placeholder="https://www.yourwebsite.com"
//                                                 type="url"
//                                                 value={website}
//                                                 onChange={handleWebsiteChange}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <p style={{ color: "red" }} className="error">
//                                     {websiteError}
//                                 </p>
//                             </>
//                         )}

//                         <div className="d-flex ">
//                             <input
//                                 checked={isSubDomain}
//                                 type="radio"
//                                 id="sub_domain"
//                                 name="domain"
//                                 className="me-2"
//                                 value="sub_domain"
//                                 onChange={handleDomainChange}
//                             />
//                             <label htmlFor="sub_domain" className="pointer fz16">I will use buildfolio sub domain.</label>
//                         </div>
//                         {isSubDomain && (
//                             <>
//                                 <p className="fz16 text-dark mt-4">Give your subdomain a name.</p>
//                                 <div className="d-flex align-items-center">
//                                     <input
//                                         type="text"
//                                         className="form-control border--rad10 max--w--184 me-3"
//                                         value={name}
//                                         onChange={(e) => {
//                                             setName(e.target.value);
//                                             setError("");
//                                         }}
//                                     />
//                                     <span className="fz18 DMSans_med">.buildfolio.co</span>
//                                 </div>
//                                 <p style={{ color: "red" }} className="error">
//                                     {error}
//                                 </p>
//                             </>
//                         )}
//                     </div>
//                     <div className="btn_cus wfc py-2 px-5 mt-4" onClick={handleOkClick}>
//                         OK
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default DomainSetup;


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../axios/axiosInstance";
import { useAppContext } from "../../context/SharedData";
import alert from "../../utils/alert";

const DomainSetup = () => {
    const { setIsWebsiteRedirected } = useAppContext()
    const navigate = useNavigate();
    const [isSubDomain, setIsSubDomain] = useState(false);
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [website, setWebsite] = useState("");
    const [websiteError, setWebsiteError] = useState("");

    const handleDomainChange = (event) => {
        setWebsite("")
        setName("")
        setError("");
        setWebsiteError("");
        setIsSubDomain(event.target.value === "sub_domain");
    };

    const handleWebsiteChange = (e) => {
        const url = e.target.value.trim();
        setWebsite(url);

        // Basic URL validation
        if (url.length > 0 && !isValidUrl(url)) {
            setWebsiteError("Please enter a valid website URL");
        } else {
            setWebsiteError("");
        }
    };

    const isValidUrl = (url) => {
        // Adjusted URL validation regex
        const urlRegex = /^(?!www\.|https:\/\/)([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/;
        return urlRegex.test(url);
    };

    const handleOkClick = async () => {
        if (isSubDomain && name.trim().length < 1) {
            setError("Please enter subdomain name");
            return;
        }
        if (!isSubDomain && website.trim().length < 1) {
            setWebsiteError("Please enter website URL");
            return;
        }
        if (!isSubDomain && websiteError) {
            setWebsiteError("Please enter a valid website URL");
            return;
        }
        if (isSubDomain && !isValidSubdomainName(name)) {
            setError("Please enter a valid subdomain name (only alphabets allowed)");
            return;
        }

        try {
            const response = await Axios.post(`website`, {
                website: website ? website : name + ".buildfolio.co",
                isOwnWebsite: isSubDomain ? false : true
            });

            if (response) {

                alert("success", response.data.message)
                navigate("/");
                setIsWebsiteRedirected(true)
            }


        } catch (error) {
            alert("error",error.response.data.error)
            console.error('Error fetching saved cards:', error);
        }
    };

    const isValidSubdomainName = (subdomain) => {
        // Check if subdomain contains only alphabetic characters
        const subdomainRegex = /^[a-zA-Z]+$/;
        return subdomainRegex.test(subdomain);
    };



    return (
        <>
            <div className="row mt-4">
                <div className="col-lg-4 col-md-6">
                    <div className="d-flex align-items-start">
                        <img onClick={() => navigate(-1)} src="img/backicon.svg" alt="back icon" className='me-2 mt-1 pointer' />
                        <p className="text-dark fz16"> To access the <b>“Need a website feature”,</b> you'll need to subscribe to our premium plan.</p>
                    </div>
                    <div className="bg__light__gray py-4 px-3 border--rad10">
                        <p className="text-dark fz16"> Add these details to your domain.</p>
                        <div className="d-flex mb-3">
                            <input
                                checked={!isSubDomain}
                                type="radio"
                                id="my_domain"
                                name="domain"
                                className="me-2"
                                value="my_domain"
                                onChange={handleDomainChange}
                            />
                            <label htmlFor="my_domain" className="pointer fz16">I have my own domain.</label>
                        </div>
                        {!isSubDomain && (
                            <>
                                <div className="border--gray--secondry border--rad10 p-3 my-sm-4 my-3 bg-white">
                                    <div className="row">
                                        <div className="col-6">
                                            <label>DNS Type</label>
                                            <div className="output__data--txt">AN</div>
                                        </div>
                                        <div className="col-6">
                                            <label>Domain Name</label>
                                            <div className="output__data--txt">James Potter</div>
                                        </div>
                                        <div className="center_line--dash my-3" />
                                        <div className="col-6">
                                            <label>IP Address</label>
                                            <div className="output__data--txt">10.204.125.24</div>
                                        </div>
                                        <div className="col-6">
                                            <label>TTL</label>
                                            <div className="output__data--txt">3600 (1 hr)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="input_grp mb-4">
                                    <div className="d-flex align-items-start">
                                        <img src="img/webIcon.svg" alt="website icon" className="icon_input me-2 mt-1" />
                                        <div className="input_itm w-100">
                                            <label htmlFor="website" className="d-block">
                                                Website
                                            </label>
                                            <input
                                                id="website"
                                                className="inputFill border-0"
                                                placeholder="yourwebsite.com"
                                                type="url"
                                                value={website}
                                                onChange={handleWebsiteChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <p style={{ color: "red" }} className="error">
                                    {websiteError}
                                </p>
                            </>
                        )}

                        <div className="d-flex ">
                            <input
                                checked={isSubDomain}
                                type="radio"
                                id="sub_domain"
                                name="domain"
                                className="me-2"
                                value="sub_domain"
                                onChange={handleDomainChange}
                            />
                            <label htmlFor="sub_domain" className="pointer fz16">I will use buildfolio sub domain.</label>
                        </div>
                        {isSubDomain && (
                            <>
                                <p className="fz16 text-dark mt-4">Give your subdomain a name.</p>
                                <div className="d-flex align-items-center">
                                    <input
                                        type="text"
                                        className="form-control border--rad10 max--w--184 me-3"
                                        value={name}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            setName(inputValue);
                                            if (isSubDomain && !isValidSubdomainName(inputValue)) {
                                                setError("Please enter a valid subdomain name (only alphabets allowed)");
                                                return;
                                            }
                                            // Remove non-alphabet characters and disallowed strings
                                            // const sanitizedValue = inputValue.replace(/[^a-zA-Z]/g, "").replace(/\b(?:http|www)\b/g, "");

                                            setError("");
                                        }}
                                    />
                                    <span className="fz18 DMSans_med">.buildfolio.co</span>
                                </div>
                                <p style={{ color: "red" }} className="error">
                                    {error}
                                </p>
                            </>
                        )}
                    </div>
                    <div className="btn_cus wfc py-2 px-5 mt-4" onClick={handleOkClick}>
                        OK
                    </div>
                </div>
            </div>
        </>
    );
};

export default DomainSetup;
